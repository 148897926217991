"use strict";

let Logger = require("../Logger.js");

let log = new Logger("d:SortSelect", window.logLevels.directives || 4);

angular.module("miw").directive("sortSelect", ["$rootScope", function($rootScope) {
    "ngInject";

    return {
        restrict : "A",
        templateUrl: "script/templates/sort-select.html",

        link: function(scope, element, attrs) {
            log.fn("link", arguments);

            scope.data     = scope.$eval(attrs.sortSelect);
            scope.sortedAs = attrs.defaultValue;

            scope.$watch("sortedAs", function(newValue, oldValue) {
                log.fn("watch (sortedAs)", arguments);

                if(newValue !== oldValue)
                    $rootScope.$broadcast("emitChangeOrder", {
                        orderby : newValue.substring(1),
                        order   : (newValue[0] === "-" ? "DESC" : "ASC")
                    });
            }, true);
        }
    };
}]);
