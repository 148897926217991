"use strict";

let Logger = require("../../Logger.js");

let log = new Logger("d:SubjectSelect", window.logLevels.directives || 4);

angular.module("miw").directive("subjectSelect", ["contentService", function(contentService) {
    "ngInject";

    return {
        restrict : "C",
        templateUrl: "script/templates/questionnaire-wizard/subject-select.html",
        scope: {
            data : "=ngModel"
        },

        link: function(scope, element, attrs) {
            log.fn("link", arguments);

            scope.step        = parseInt(attrs.step);
            scope.steps       = parseInt(attrs.steps);
            scope.blockNext   = false;
            scope.translocate = {
                groups   : [],
                invitees : [],
                invited  : []
            };

            scope.$watch("data.currentStep", function(newValue, oldValue) {
                log.fn("watch (data.currentStep)", arguments);

                if(newValue + 1 === scope.step) {
                    if(scope.translocate.groups.length === 0)
                        contentService.read("group", {}, function(response) {
                            if(response.data.status === "OK") {
                                log.v("Response OK received.");

                                scope.translocate.groups = response.data.message;
                            } else {
                                log.w("Erroneous response received.", response.data);
                            }
                        });

                    if(scope.translocate.invitees.length === 0)
                        contentService.read("subject/getAll", {}, function(response) {
                            if(response.data.status === "OK") {
                                log.v("Response OK received.");

                                angular.forEach(response.data.message, function(value) {
                                    let typeArray = {
                                        Conjugations: value.Conjugations,
                                        Subjects: []
                                    };

                                    angular.forEach(value.Subjects, function(value) {
                                        let id = parseInt(value.ID);

                                        if(scope.data.exclude.indexOf(id) === -1)
                                            this.push(value);
                                    }, typeArray.Subjects);

                                    if(typeArray.Subjects.length > 0)
                                        this.push(typeArray);
                                }, scope.translocate.invitees);

                                log.d("Parsed selectable subjects", scope.translocate.invitees);

                                angular.copy(scope.translocate.invitees, scope.translocate.invited);
                                scope.translocate.invited.forEach(function(value) {
                                    let subjectSelf = value.Subjects.find(function(item) {
                                        return scope.data.subjects !== undefined && scope.data.subjects.indexOf(item.ID) !== -1;
                                    });

                                    value.Subjects = [];

                                    if(subjectSelf !== undefined)
                                        value.Subjects.push(subjectSelf);
                                });
                            } else {
                                log.w("Erroneous response received.", response.data);
                            }
                        });
                }
            });

            scope.$watch("data.subjects", function(newValue, oldValue) {
                log.fn("watch (data.subjects)", arguments);

                scope.data.parsed.subjects = scope.translocate.invited;
                scope.blockNext            = scope.data.subjects !== undefined && scope.data.subjects.length < 1;
            }, true);

            scope.wizardPrevious = function() {
                log.fn("wizardPrevious", arguments);

                scope.$emit("emitWizardPrevious");
            };

            scope.wizardNext = function() {
                log.fn("wizardNext", arguments);

                scope.$emit("emitWizardNext");
            };
        }
    };
}]);
