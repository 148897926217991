"use strict";

let Logger = require("../../Logger.js");

let log = new Logger("c:GroupsProfile", window.logLevels.controller || 4);

angular.module("miw").controller("groupsProfileController", ["$scope", "$routeParams", "$compile", "contentService", function($scope, $routeParams, $compile, contentService) {
    "ngInject";

    let unregister = [];

    $scope.id       = $routeParams.id;
    $scope.data     = null;
    $scope.editMode = false;
    $scope.subjects = [];

    $scope.translocate = {
        invitees : [],
        invited  : []
    };

    $scope.wizardSteps = [
        "questionnaire-select",
        "subject-select",
        "invitee-select",
        "personalise-data",
        "check-data"
    ];

    $scope.editWizardSteps = [
        "invitee-select",
        "personalise-data",
        "check-data",
    ];

    $scope.$emit("emitRouteChanged");
    $scope.$emit("emitRequestNavigation");
    $scope.$emit("emitSetActiveButton", {button: 3});
    $scope.$emit("emitSetActiveItem", {item: 0});
    $scope.$emit("emitShowAvatar", true);
    $scope.$emit("emitSetSearchable", false);

    $scope.$on("$destroy", function() {
        log.fn("destroy", arguments);

        $scope.id              = null;
        $scope.data            = null;
        $scope.editMode        = null;
        $scope.subjects        = null;
        $scope.translocate     = null;
        $scope.wizardSteps     = null;
        $scope.editWizardSteps = null;

        for(let i = unregister - 1; i >= 0; i--)
            unregister[i]();

        unregister = null;

        $scope.getSubjects         = function() {};
        $scope.flipcard            = function() {};
        $scope.save                = function() {};
        $scope.startWizard         = function() {};
        $scope.stopWizard          = function() {};
        $scope.profileOnly         = function() {};
        $scope.remind              = function() {};
        $scope.editQuestionnaire   = function() {};
        $scope.deleteQuestionnaire = function() {};
        $scope.refreshData         = function() {};
    });

    unregister.push($scope.$on("emitStopWizard", function(event, args) {
        log.fn("onEmitStopWizard", arguments);

        $scope.stopWizard(args.element);
    }));

    unregister.push($scope.$on("emitSendWizard", function(event, args) {
        log.fn("onEmitSendWizard", arguments);

        $scope.stopWizard(args.element);

        log.d("sending data in " + args.state + " mode", args.data);

        $scope.$emit("emitAnalyticsEvent", {
            event    : args.state,
            category : "userAction",
            label    : "Applying action " + args.state + " on questionnaireController"
        });

        contentService.create("questionnaire/" + args.state + "/" + (args.questionnaire !== undefined ? args.questionnaire : ""), args.data, function(response) {
            if(response.data.status === "OK") {
                log.v("Response OK received.");

                $scope.refreshData();
            } else {
                log.w("Erroneous response received.", response.data);

                let title = "Error";
                let content = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined) {
                    window.console.error(response.data.exception);

                    title   = "Internal Exception";
                    content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                }

                $scope.quickPop(title, content);
            }
        }, $scope.callbackFailure);
    }));

    $scope.getSubjects = function() {
        if($scope.translocate.invitees.length === 0)
            contentService.read("subject/getAll", {}, function(response) {
                if(response.data.status === "OK") {
                    log.v("Response OK received.");

                    $scope.translocate.invitees = response.data.message;

                    log.d("Parsed selectable subjects", $scope.translocate.invitees);

                    angular.copy($scope.translocate.invitees, $scope.translocate.invited);
                    $scope.translocate.invited.forEach(function(value) {
                        let tmp = [];

                        if($scope.subjects !== undefined) {
                            value.Subjects.forEach(function(item) {
                                if($scope.subjects.indexOf(item.ID) !== -1)
                                    tmp.push(item);
                            });
                        }

                        value.Subjects = tmp;
                    });
                } else {
                    log.w("Erroneous response received.", response.data);
                }
            });
    };

    $scope.flipcard = function() {
        log.fn("flipcard", arguments);

        log.d("scope", $scope);

        $scope.getSubjects();

        $scope.editMode = "update";
    };

    $scope.save = function() {
        log.fn("save", arguments);

        let data = {
            Name     : $scope.data.Group.Name,
            Subjects : $scope.subjects
        };

        let callbackSuccess = function(response) {
            if(response.data.status === "OK") {
                log.v("Response OK received.");

                if(Array.isArray(response.data.message))
                    return $scope.go("groups/profile/" + response.data.message[0].ID);

                $scope.refreshData();
                $scope.editMode = false;
            } else {
                log.w("Erroneous response received.", response.data);

                let title = "Error";
                let content = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined) {
                    window.console.error(response.data.exception);

                    title   = "Internal Exception";
                    content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                }

                $scope.quickPop(title, content);
            }
        };

        switch($scope.editMode) {
            case "update":
                log.v("Updating group");

                $scope.$emit("emitAnalyticsEvent", {
                    event    : "update",
                    category : "userAction",
                    label    : "Updating group " + $scope.data.Group.ID
                });

                contentService.replace("group/put/" + $scope.data.Group.ID, data, callbackSuccess, $scope.callbackFailure);
                break;
            case "new":
                log.v("Creating group");

                $scope.$emit("emitAnalyticsEvent", {
                    event    : "create",
                    category : "userAction",
                    label    : "Creating new group"
                });

                contentService.create("group/create/", data, callbackSuccess, $scope.callbackFailure);
                break;
            default:
                return log.e("Invalid edit mode: ", $scope.editMode);
        }
    };

    $scope.startWizard = function() {
        let wizard   = angular.element("<questionnaire-wizard ng-model=\"wizardSteps\" data-extended=\"" + $scope.extended + "\" data-title=\"Nieuwe lijst uitzetten\" data-subject=\"{{data.Subject}}\"></questionnaire-wizard>");
        let wizardEl = $compile(wizard)($scope);

        $scope.$emit("emitToggleScroll");

        angular.element(document.body).append(wizardEl);
    };

    $scope.stopWizard = function(wizardEl) {
        $scope.$emit("emitToggleScroll");

        angular.element(wizardEl).remove();
    };

    $scope.profileOnly = function() {
        $scope.quickPop("Melding", "Deze actie is enkel beschikbaar op de profielpagina van de participant.");
    };

    $scope.remind = function(questionnaire) {
        log.fn("remind", arguments);

        $scope.$emit("emitAnalyticsEvent", {
            event    : "remind",
            category : "userAction",
            label    : "Request remind of questionnaire " + questionnaire.ID
        });

        contentService.create("questionnaire/remind/" + questionnaire.ID, {

        }, function(response) {
            if(response.data.status === "OK") {
                log.v("Response OK received.");

                $scope.quickPop("Melding", response.data.message.Reminders + " " + (parseInt(response.data.message.Reminders) === 1 ? "herinnering" : "herinneringen") + " verstuurd.");
            } else {
                log.w("Erroneous response received.", response.data);

                let title = "Error";
                let content = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined) {
                    window.console.error(response.data.exception);

                    title   = "Internal Exception";
                    content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                }

                $scope.quickPop(title, content);
            }
        }, $scope.callbackFailure);
    };

    $scope.editQuestionnaire = function(questionnaire) {
        log.fn("editQuestionnaire", arguments);

        let questionnaireIdentifier = questionnaire.ID;
        let inviteeExclude = JSON.stringify(questionnaire.Data.Invited.Raw);

        log.d("Binding data to wizard", {
            questionnaireIdentifier: questionnaireIdentifier,
            inviteeExclude: inviteeExclude
        });

        let wizard   = angular.element("<questionnaire-wizard ng-model=\"editWizardSteps\" data-title=\"Lijst bewerken\" data-questionnaire-identifier=\"" + questionnaireIdentifier + "\" data-invitee-exclude=\"" + inviteeExclude + "\"></questionnaire-wizard>");
        let wizardEl = $compile(wizard)($scope);

        $scope.$emit("emitToggleScroll");

        angular.element(document.body).append(wizardEl);
    };

    $scope.deleteQuestionnaire = function(questionnaire) {
        log.fn("deleteQuestionnaire", arguments);

        $scope.confirmationPopup("Bevestig actie", "Weet u zeker dat u deze lijst wilt verwijderen?", function(popup) {
            log.fn("confirmationPopup:confirmed", arguments);

            log.v("Operation confirmed");

            $scope.$emit("emitAnalyticsEvent", {
                event    : "delete",
                category : "userAction",
                label    : "Request deleteion of questionnaire " + questionnaire.ID
            });

            popup.mutate("Lijst wordt verwijderd", "", []).withLoader();

            contentService.delete("questionnaire/delete/" + questionnaire.ID, function(response) {
                if(response.data.status === "OK") {
                    log.v("Response OK received.");

                    $scope.data.Questionnaires.splice($scope.data.Questionnaires.indexOf(questionnaire), 1);
                    $scope.refreshData();
                    $scope.quickPop("Lijst is verwijderd", "", popup);
                } else {
                    log.w("Erroneous response received.", response.data);

                    let title = "Error";
                    let content = response.data.message || response.data || "Request failed";

                    if(response.data.exception !== undefined) {
                        window.console.error(response.data.exception);

                        title   = "Internal Exception";
                        content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                    }

                    $scope.quickPop(title, content, popup);
                }
            }, function(response) {
                $scope.callbackFailure(response, popup);
            });
        }, function(popup) {
            log.fn("confirmationPopup:cancelled", arguments);

            log.v("Operation cancelled");
        });
    };

    $scope.refreshData = function() {
        contentService.read("group/profile/" + $scope.id, {

        }, function(response) {
            if(response.data.status === "OK") {
                log.v("Response OK received.");

                $scope.data     = response.data.message;
                $scope.subjects = $scope.data.Group.Raw;

                if($scope.editMode === "update")
                    $scope.getSubjects();
            } else {
                log.w("Erroneous response received.", response.data);

                let title = "Error";
                let content = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined) {
                    window.console.error(response.data.exception);

                    title   = "Internal Exception";
                    content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                }

                $scope.quickPop(title, content);
            }
        }, function(response) {
            $scope.data = [];

            $scope.callbackFailure(response);
        });
    };

    if($scope.id !== undefined) {
        if($routeParams.edit !== undefined && $routeParams.edit === "edit")
            $scope.editMode = "update";

        $scope.refreshData();
    } else {
        $scope.editMode = "new";

        $scope.$emit("emitAnalyticsEvent", {
            event    : "create",
            category : "userAction",
            label    : "Create new group"
        });

        $scope.getSubjects();

        $scope.data = {
            Group : {
                Name     : "",
                Subjects : []
            }
        };
    }

}]);
