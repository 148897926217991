"use strict";

let Logger = require("../Logger.js");

let log = new Logger("c:Cookie", window.logLevels.controller || 4);

angular.module("miw").controller("cookieController", ["$scope", function($scope) {
    "ngInject";

    $scope.$emit("emitRouteChanged");
    $scope.$emit("emitHideNavigation");
    $scope.$emit("emitSetActiveButton", {button: 0});
    $scope.$emit("emitSetActiveItem", {item: 0});
    $scope.$emit("emitShowAvatar", false);
    $scope.$emit("emitSetSearchable", false);

    $scope.$on("$destroy", function() {
        log.fn("destroy", arguments);
    });

}]);
