"use strict";

let Logger = require("../Logger.js");

let log = new Logger("d:Navigation", window.logLevels.directives || 4);

angular.module("miw").directive("navigation", ["$rootScope", function($rootScope) {
    "ngInject";

    return {
        restrict : "E",
        templateUrl: "script/templates/navigation.html",

        link: function(scope, element, attrs) {
            log.fn("link", arguments);
        }
    };
}]);
