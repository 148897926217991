"use strict";

let Logger = require("../Logger.js");

let log = new Logger("d:ForgottenButton", window.logLevels.directives || 4);

angular.module("miw").directive("forgottenButton", ["$rootScope", function($rootScope) {
    "ngInject";

    return {
        restrict : "C",
        scope: {
            credentials : "=ngModel"
        },

        link: function(scope, element, attrs) {
            log.fn("link", arguments);

            let username = scope.credentials !== undefined ? scope.credentials.username || undefined : undefined;

            scope.$watch("credentials", function(newValue, oldValue) {
                log.fn("watch (credentials)", arguments);

                if(newValue !== undefined)
                    username = newValue.username !== "" ? newValue.username : undefined;

                attrs.$set("disabled", username === undefined);
            }, true);

            scope.$on("emitReady", function(event, args) {
                log.fn("onEmitReady", arguments);

                attrs.$set("disabled", username === undefined);
            });

            scope.$on("emitError", function(event, args) {
                log.fn("onEmitError", arguments);

                attrs.$set("disabled", true);
            });

            element.bind("click", function(event) {
                log.fn("click", arguments);

                if(!event.isTrusted)
                    return log.w("Please click the element yourself!");

                attrs.$set("disabled", true);

                $rootScope.$broadcast("emitForgottenSendable", {e: event});
            });
        }
    };
}]);
