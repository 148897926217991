"use strict";

// Globals. Because why not, right?
window.logLevels = {
    global     : -1,
    controller : -1,
    directives : -1,
    service    : -1
};

let angular = require("angular");

require("angulartics");
require("angular-route");

angular.module("miw", ["ngRoute", "angulartics", require("angulartics-google-analytics")]).config(["$routeProvider", "$locationProvider", "$analyticsProvider", function($routeProvider, $locationProvider, $analyticsProvider) {
    "ngInject";

    $routeProvider.when("/error/:code", {
        controller  : "errorController",
        templateUrl : "ngViews/reroute.html"
    }).when("/privacypolicy", {
        controller  : "privpolController",
        templateUrl : "ngViews/privacypolicy.html"
    }).when("/cookies", {
        controller  : "cookieController",
        templateUrl : "ngViews/cookies.html"
    }).when("/profile", {
        controller  : "profileController",
        templateUrl : "ngViews/profile.html"
    }).when("/subjects/:type", {
        controller  : "subjectsOverviewController",
        templateUrl : "ngViews/subjects/overview.html"
    }).when("/subjects/profile/:id/:edit?", {
        controller  : "subjectsProfileController",
        templateUrl : "ngViews/subjects/profile.html"
    }).when("/subjects/new/:type", {
        controller  : "subjectsProfileController",
        templateUrl : "ngViews/subjects/profile.html"
    }).when("/groups", {
        controller  : "groupsOverviewController",
        templateUrl : "ngViews/groups/overview.html"
    }).when("/groups/profile/:id/:edit?", {
        controller  : "groupsProfileController",
        templateUrl : "ngViews/groups/profile.html"
    }).when("/groups/new", {
        controller  : "groupsProfileController",
        templateUrl : "ngViews/groups/profile.html"
    }).when("/questionnaires", {
        controller  : "questionnairesOverviewController",
        templateUrl : "ngViews/questionnaires/overview.html"
    }).when("/questionnaires/available", {
        controller  : "questionnairesAvailableController",
        templateUrl : "ngViews/questionnaires/available.html"
    }).when("/questionnaires/order", {
        controller  : "questionnairesOrderController",
        templateUrl : "ngViews/questionnaires/order.html"
    }).when("/questionnaires/report/:id", {
        controller  : "questionnairesReportController",
        templateUrl : "ngViews/questionnaires/report.html"
    }).when("/questionnaires/download/:id/:names?", {
        controller  : "questionnairesDownloadController",
        templateUrl : "ngViews/questionnaires/download.html"
    }).when("", {
        redirectTo: "/profile"
    }).when("/", {
        redirectTo: "/profile"
    }).otherwise({redirectTo: "/error/404"});

    $locationProvider.html5Mode(true);

    $analyticsProvider.virtualPageviews(true);
}]);

// directives
require("CredentialsInput.js");
require("PasswordButton.js");
require("LoginButton.js");
require("ForgottenButton.js");
require("Navigation.js");
require("ScrollClass.js");
require("ScrollAction.js");
require("SaveButton.js");
require("TranslocateSelect.js");
require("QuestionnairePreview.js");
require("SortSelect.js");

require("QuestionnaireWizard.js");
require("questionnaire-wizard/QuestionnaireSelect.js");
require("questionnaire-wizard/SubjectSelect.js");
require("questionnaire-wizard/InviteeSelect.js");
require("questionnaire-wizard/CheckData.js");
require("questionnaire-wizard/PersonaliseData.js");

// services
require("ContentService.js");

// controllers
require("LoginController.js");
require("MainController.js");
require("ErrorController.js");
require("ProfileController.js");
require("CookieController.js");
require("PrivpolController.js");

require("subjects/OverviewController.js");
require("subjects/ProfileController.js");

require("groups/OverviewController.js");
require("groups/ProfileController.js");

require("questionnaires/OverviewController.js");
require("questionnaires/AvailableController.js");
require("questionnaires/OrderController.js");
require("questionnaires/ReportController.js");
require("questionnaires/DownloadController.js");
