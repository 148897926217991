"use strict";

/* jshint node : true  */

/**
 * calculatePasswordEntropy
 *   Quick function to give an indication of password entropy
 *
 * @param  string password string to calculate indication of
 * @return int             entropy score
 */
function PasswordEntropyCalculator(password) {
    let score          = 0;
    let letters        = {};
    let variationCount = 0;
    let variations     = {
        digits   : /\d/.test(password),
        lower    : /[a-z]/.test(password),
        upper    : /[A-Z]/.test(password),
        nonWords : /\W/.test(password),
    };
    let disqualify = [
        "password"    , "Password"    , "PASSWORD"    ,
        "password0"   , "Password0"   , "PASSWORD0"   ,
        "password)"   , "Password)"   , "PASSWORD)"   ,
        "password1"   , "Password1"   , "PASSWORD1"   ,
        "password!"   , "Password!"   , "PASSWORD!"   ,
        "password2"   , "Password2"   , "PASSWORD2"   ,
        "password@"   , "Password@"   , "PASSWORD@"   ,
        "password3"   , "Password3"   , "PASSWORD3"   ,
        "password#"   , "Password#"   , "PASSWORD#"   ,
        "password4"   , "Password4"   , "PASSWORD4"   ,
        "password$"   , "Password$"   , "PASSWORD$"   ,
        "password5"   , "Password5"   , "PASSWORD5"   ,
        "password%"   , "Password%"   , "PASSWORD%"   ,
        "password6"   , "Password6"   , "PASSWORD6"   ,
        "password^"   , "Password^"   , "PASSWORD^"   ,
        "password7"   , "Password7"   , "PASSWORD7"   ,
        "password&"   , "Password&"   , "PASSWORD&"   ,
        "password8"   , "Password8"   , "PASSWORD8"   ,
        "password*"   , "Password*"   , "PASSWORD*"   ,
        "password9"   , "Password9"   , "PASSWORD9"   ,
        "password("   , "Password("   , "PASSWORD("   ,
        "trustnoone"  , "TrustNoOne"  , "Trustnoone"  , "TRUSTNOONE",
        "trustno1"    , "TrustNo1"    , "Trustno1"    , "TRUSTNO1"  ,
        "trustno!"    , "TrustNo!"    , "Trustno!"    , "TRUSTNO!"  ,
        "000000"      , "111111"      , "222222"      , "333333"    ,
        "444444"      , "555555"      , "666666"      , "777777"    , "888888"    , "999999"   ,
        "1234"        , "12345"       , "123456"      , "1234567"   , "12345678"  , "123456789", "1234567890", "0123456789",
        "abc123"      , "Abc123"      , "ABC123"      ,
        "abc!@#"      , "Abc!@#"      , "ABC!@#"      ,
        "letmein"     , "LetMeIn"     , "Letmein"     , "LETMEIN"   ,
        "admin"       , "Admin"       , "ADMIN"       ,
        "qwerty"      , "Qwerty"      , "QWERTY"      ,
        "azerty"      , "Azerty"      , "AZERTY"      ,
        "123qweasdzxc", "123QWEASDZXC", "!@#QWEASDZXC",
        "monkey"      , "Monkey"      , "MONKEY"      ,
        "shadow"      , "Shadow"      , "SHADOW"      ,
        "sunshine"    , "Sunshine"    , "SUNSHINE"    ,
        "princess"    , "Princess"    , "PRINCESS"    ,
    ];

    this.getEntropy = function() {
        for(let i = 0; i < password.length; i++) {
            letters[password[i]] = (letters[password[i]] || 0) + 1;
            score += parseInt(5.0 / letters[password[i]]);
        }

        for(let check in variations)
            variationCount += (variations[check] === true) ? 1 : 0;

        score += parseInt((variationCount - 1) * 10);

        if(disqualify.indexOf(password) !== -1)
            score = 0;

        if(score < 0)
            score = 0;

        return score;
    };

    return this;
}

module.exports = PasswordEntropyCalculator;
