"use strict";

let Logger = require("../Logger.js");

let log = new Logger("d:QuestionnairePreview", window.logLevels.directives || 4);

angular.module("miw").directive("questionnairePreview", ["$compile", "$rootScope", "contentService", function($compile, $rootScope, contentService) {
    "ngInject";

    return {
        restrict : "C",
        templateUrl: "script/templates/questionnaire-preview.html",
        scope: {
            data : "=ngModel"
        },

        link: function(scope, element, attrs) {
            log.fn("link", arguments);

            scope.close = function() {
                $rootScope.$broadcast("emitClosePreview", {element: element});
            };
        }
    };
}]);
