"use strict";

let Logger = require("../../Logger.js");

let log = new Logger("c:QuestionnairesAvailable", window.logLevels.controller || 4);

angular.module("miw").controller("questionnairesAvailableController", ["$scope", "$compile", "contentService", function($scope, $compile, contentService) {
    "ngInject";

    let unregister = [];

    let get = [
        "extra",
        "custom",
        "primary"
    ];

    $scope.data = {
        primary : null,
        custom  : null,
        extra   : null
    };

    $scope.$emit("emitRouteChanged");
    $scope.$emit("emitRequestNavigation");
    $scope.$emit("emitSetActiveButton", {button: 4});
    $scope.$emit("emitSetActiveItem", {item: "L2"});
    $scope.$emit("emitShowAvatar", true);
    $scope.$emit("emitSetSearchable", false);

    $scope.$on("$destroy", function() {
        log.fn("destroy", arguments);

        $scope.data = null;

        for(let i = unregister - 1; i >= 0; i--)
            unregister[i]();

        unregister = null;

        $scope.showQuestions = function() {};
    });

    unregister.push($scope.$on("emitClosePreview", function(event, args) {
        log.fn("onEmitClosePreview", arguments);

        $scope.$emit("emitToggleScroll");

        angular.element(args.element).remove();
    }));

    $scope.showQuestions = function(type, $index) {
        log.fn("showQuestions", arguments);

        $scope.previewQuestionnaire = {};

        switch(type) {
            case 0: $scope.previewQuestionnaire = $scope.data.primary[$index]; break;
            case 1: $scope.previewQuestionnaire = $scope.data.custom[$index];  break;
            case 2: $scope.previewQuestionnaire = $scope.data.extra[$index];   break;
        }

        log.d("Questionnaire selected", $scope.previewQuestionnaire);

        $scope.$emit("emitToggleScroll");

        angular.element(document.body).append($compile(angular.element("<div class=\"questionnaire-preview\" ng-model=\"previewQuestionnaire\"></div>"))($scope));
    };

    angular.forEach(get, function(value) {
        contentService.read("questionnaire/get" + value + "/", {

        }, function(response) {
            if(response.data.status === "OK") {
                log.v("Response OK received.");

                if($scope.data[value] === null)
                    $scope.data[value] = [];

                if(Array.isArray(response.data.message))
                    $scope.data[value] = response.data.message;
            } else {
                log.w("Erroneous response received.", response.data);

                let title = "Error";
                let content = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined) {
                    window.console.error(response.data.exception);

                    title   = "Internal Exception";
                    content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                }

                $scope.quickPop(title, content);
            }
        }, function(response) {
            if($scope.data[value] === null)
                $scope.data[value] = [];

            $scope.callbackFailure(response);
        });
    });

}]);
