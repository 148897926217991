"use strict";

let Logger = require("../../Logger.js");

let log = new Logger("c:QuestionnairesDownload", window.logLevels.controller || 4);

angular.module("miw").controller("questionnairesDownloadController", ["$window", "$scope", "$routeParams", "$compile", "$timeout", "contentService", function($window, $scope, $routeParams, $compile, $timeout, contentService) {
    "ngInject";

    $scope.compare   = ($routeParams.id.indexOf(",") !== -1) ? "?compare=" : "";
    $scope.hideNames = $routeParams.names;
    $scope.data      = null;

    $scope.$emit("emitRequestNavigation");
    $scope.$emit("emitShowAvatar", true);

    angular.element(document.querySelector("body"))[0].classList.add("print");

    $scope.$on("$destroy", function() {
        log.fn("destroy", arguments);

        $scope.compare = null;
        $scope.data    = null;
    });

    contentService.read("answer/download/" + $scope.compare + $routeParams.id, {

    }, function(response) {
        if(response.data.status === "OK") {
            log.v("Response OK received.");

            $scope.data = response.data.message;

            $window.document.title = "Rapportage van lijst " + $routeParams.id;

            log.d("data received", $scope.data);

            $timeout(function($window) {
                let verticalContainers = document.querySelectorAll(".vertical-container");

                for(let i = verticalContainers.length - 1; i >= 0; i--) {
                    let verticals = verticalContainers[i].querySelectorAll(".vertical");
                    let height    = 0;

                    for(let j = verticals.length - 1; j >= 0; j--) {
                        let h = angular.element(verticals[j])[0].clientWidth;

                        if(h > height)
                            height = h;
                    }

                    for(let j = verticals.length - 1; j >= 0; j--)
                        angular.element(verticals[j])[0].parentNode.style.height = height + 20 + "px";
                }

                $window.print();
                $window.close();
            }, 1000, true, $window);
        } else {
            log.w("Erroneous response received.", response.data);

            let title = "Error";
            let content = response.data.message || response.data || "Request failed";

            if(response.data.exception !== undefined) {
                window.console.error(response.data.exception);

                title   = "Internal Exception";
                content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
            }

            $scope.data = [];

            $scope.quickPop(title, content);
        }
    }, function(response) {
        $scope.data = [];

        $scope.callbackFailure(response);
    });

}]);
