"use strict";

let Logger = require("../../Logger.js");

let log = new Logger("c:QuestionnairesReport", window.logLevels.controller || 4);

angular.module("miw").controller("questionnairesReportController", ["$window", "$scope", "$routeParams", "$compile", "$timeout", "contentService", function($window, $scope, $routeParams, $compile, $timeout, contentService) {
    "ngInject";

    $scope.compare         = ($routeParams.id.indexOf(",") !== -1) ? "?compare=" : "";
    $scope.hideAll         = false;
    $scope.hide            = {};
    $scope.topEl           = null;
    $scope.data            = null;
    $scope.calculatedStyle = {opacity: 0};

    $scope.$emit("emitRouteChanged");
    $scope.$emit("emitRequestNavigation");
    $scope.$emit("emitSetActiveButton", {button: 4});
    $scope.$emit("emitSetActiveItem", {item: 0});
    $scope.$emit("emitShowAvatar", true);
    $scope.$emit("emitSetSearchable", false);

    $scope.$on("$destroy", function() {
        log.fn("destroy", arguments);

        $scope.topEl[0].parentNode.removeChild($scope.topEl[0]);

        $scope.compare         = null;
        $scope.hideAll         = null;
        $scope.hide            = null;
        $scope.topEl           = null;
        $scope.data            = null;
        $scope.calculatedStyle = null;

        $scope.toggleAll = function() {};
    });

    $scope.toggleAll = function(value) {
        log.fn("toggleAll", arguments);

        $scope.hideAll = value;

        angular.forEach($scope.hide, function(value, index) {
            $scope.hide[index] = $scope.hideAll;
        });
    };

    contentService.read("answer/report/" + $scope.compare + $routeParams.id, {

    }, function(response) {
        if(response.data.status === "OK") {
            log.v("Response OK received.");

            $scope.data = response.data.message;

            log.d("data received", $scope.data);

            $timeout(function($scope) {
                $scope.calculatedStyle.opacity = 1;
                $scope.calculatedStyle.width   = angular.element(document.querySelector(".answer"))[0].clientWidth + "px";

                let calculatedOffset = angular.element(document.querySelector("#report-domain"))[0].offsetTop;

                let top = angular.element("<div class=\"back-to-top\" scroll-class=\"visible\" offset=" + calculatedOffset + " ng-click=\"scrollTo('questionnaire-report')\"></div>");

                $scope.topEl = $compile(top)($scope);

                angular.element(document.body).append($scope.topEl);
            }, 1000, true, $scope);
        } else {
            log.w("Erroneous response received.", response.data);

            let title = "Error";
            let content = response.data.message || response.data || "Request failed";

            if(response.data.exception !== undefined) {
                window.console.error(response.data.exception);

                title   = "Internal Exception";
                content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
            }

            $scope.data = [];

            $scope.quickPop(title, content);
        }
    }, function(response) {
        $scope.data = [];

        $scope.callbackFailure(response);
    });

}]);
