"use strict";

let Logger = require("../Logger.js");

let log = new Logger("d:TranslocateSelect", window.logLevels.directives || 4);

angular.module("miw").directive("translocateSelect", ["$filter", function($filter) {
    "ngInject";

    return {
        restrict : "A",
        require  : ["ngModel"],
        templateUrl: "script/templates/translocate-select.html",
        scope: {
            translocateSelect : "=translocateSelect",
            selected          : "=ngModel"
        },

        link: function(scope, element, attrs) {
            log.fn("link", arguments);

            scope.moveRight = function() {
                log.fn("moveRight", arguments);

                scope.left.forEach(function(value) {
                    let data = value.split(":");

                    if(data[0] === "group") {
                        let groupKey = parseInt(data[1]);

                        log.d({
                            "invitees" : scope.translocateSelect.invitees,
                            "length"   : scope.translocateSelect.invitees.length
                        });

                        for(let inviteeIndex = scope.translocateSelect.invitees.length - 1; inviteeIndex >= 0; inviteeIndex--) {
                            for (var subjectIndex = scope.translocateSelect.invitees[inviteeIndex].Subjects.length - 1; subjectIndex >= 0; subjectIndex--) {
                                let subject = scope.translocateSelect.invitees[inviteeIndex].Subjects[subjectIndex];

                                if(scope.translocateSelect.groups[groupKey].Subjects.indexOf(subject.ID) !== -1) {

                                    scope.translocateSelect.invitees[inviteeIndex].Subjects.splice(subjectIndex, 1);
                                    scope.translocateSelect.invited[inviteeIndex].Subjects.push(subject);

                                    scope.selected.push(subject.ID);
                                }
                            }
                        }
                    } else {
                        let objKey    = parseInt(data[0]);
                        let subjectID = parseInt(data[1]);

                        scope.translocateSelect.invitees[objKey].Subjects.forEach(function(value, index) {
                            if(value.ID === subjectID) {
                                let subject = scope.translocateSelect.invitees[objKey].Subjects[index];

                                scope.translocateSelect.invitees[objKey].Subjects.splice(index, 1);
                                scope.translocateSelect.invited[objKey].Subjects.push(subject);

                                scope.selected.push(subjectID);
                            }
                        });
                    }
                });

                scope.translocateSelect.invited.forEach(function(value, index) {
                    scope.translocateSelect.invited[index].Subjects = $filter("orderBy")(scope.translocateSelect.invited[index].Subjects, "ID");
                });
            };

            scope.moveLeft = function() {
                log.fn("moveLeft", arguments);

                scope.right.forEach(function(value) {
                    let data      = value.split(":");
                    let objKey    = parseInt(data[0]);
                    let subjectID = parseInt(data[1]);

                    scope.translocateSelect.invited[objKey].Subjects.forEach(function(value, index) {
                        if(value.ID === subjectID) {
                            let subject = scope.translocateSelect.invited[objKey].Subjects[index];

                            scope.translocateSelect.invited[objKey].Subjects.splice(index, 1);
                            scope.translocateSelect.invitees[objKey].Subjects.push(subject);

                            let remove = scope.selected.indexOf(subjectID);

                            if(remove > -1)
                                scope.selected.splice(remove, 1);
                        }
                    });
                });

                scope.translocateSelect.invitees.forEach(function(value, index) {
                    scope.translocateSelect.invitees[index].Subjects = $filter("orderBy")(scope.translocateSelect.invitees[index].Subjects, "ID");
                });
            };
        }
    };

}]);