"use strict";

let Logger = require("../Logger.js");

let log = new Logger("d:CredentialsInput", window.logLevels.directives || 4);

angular.module("miw").directive("credentialsInput", ["$rootScope", function($rootScope) {
    "ngInject";

    return {
        restrict : "C",

        link: function(scope, element, attrs) {
            log.fn("link", arguments);

            scope.$on("emitFailure", function(event, args) {
                log.fn("onEmitFailure", arguments);

                angular.element(element).addClass("wrong-credentials");
            });

            scope.$on("emitSendable", function(event, args) {
                log.fn("onEmitSendable", arguments);

                angular.element(element).removeClass("wrong-credentials");
            });

            scope.$on("emitReset", function(event, args) {
                log.fn("onEmitReset", arguments);

                angular.element(element).removeClass("wrong-credentials");
            });

            element.bind("focus", function(event) {
                log.fn("focus", arguments);

                angular.element(element).removeClass("wrong-credentials");
            });

            element.bind("keyup", function(event) {
                log.fn("keyup", arguments);

                if(event.keyCode === 13 || event.key === "Enter" || event.code === "Enter" || event.code === "NumpadEnter")
                    $rootScope.$broadcast("emitNext", {e: event});
            });
        }
    };
}]);
