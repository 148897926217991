"use strict";

let Logger = require("../Logger.js");

let log = new Logger("d:ScrollClass", window.logLevels.directives || 4);

angular.module("miw").directive("scrollClass", ["$window", function($window) {
    "ngInject";

    return {
        restrict : "A",

        link: function(scope, element, attrs) {
            log.fn("link", arguments);

            angular.element($window).bind("scroll", function() {
                if(this.pageYOffset >= parseInt(attrs.offset)) {
                    element.addClass(attrs.scrollClass);
                } else {
                    element.removeClass(attrs.scrollClass);
                }
            });
        }
    };
}]);
