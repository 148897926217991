"use strict";

let Logger = require("../Logger.js");

let log = new Logger("d:ScrollAction", window.logLevels.directives || 4);

angular.module("miw").directive("scrollAction", ["$window", "$timeout", function($window, $timeout) {
    "ngInject";

    return {
        restrict : "A",

        link: function(scope, element, attrs) {
            log.fn("link", arguments);

            scope.callAction = function(offsetHeight) {
                if(!scope.$eval(attrs.enabled))
                    return;

                if(offsetHeight >= parseInt(element[0].offsetTop))
                    scope.$eval(attrs.scrollAction);
            };

            scope.$on("emitRequestNext", function() {
                log.fn("onEmitRequestNext", arguments);

                // one second should be enough when the connection is stable
                // on instable connections this might become an issue.
                // But that's not out problem.
                $timeout(scope.callAction, 1000, true, $window.pageYOffset + $window.innerHeight);
            });

            attrs.$observe("override", function(interpolatedValue) {
                if(interpolatedValue)
                    scope.callAction(Number.MAX_SAFE_INTEGER);
            });

            angular.element($window).bind("scroll", function() {
                scope.callAction(this.pageYOffset + this.innerHeight);
            });
        }
    };
}]);
