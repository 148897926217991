"use strict";

let Logger = require("../Logger.js");

let log = new Logger("c:Error", window.logLevels.controller || 4);

angular.module("miw").controller("errorController", ["$scope", "$routeParams", function($scope, $routeParams) {
    "ngInject";

    $scope.$emit("emitSetActiveButton", {button: 0});
    $scope.$emit("emitSetActiveItem", {item: 0});
    $scope.$emit("emitShowAvatar", false);
    $scope.$emit("emitSetSearchable", false);

    let code = parseInt($routeParams.code) || 0;

    log.v("Received reroute request for error code " + code);

    switch(code) {
    	case 400: $scope.$emit("emitErrorReroute", {code : code}); break;
    	case 404: $scope.$emit("emitErrorReroute", {code : code}); break;
    	case 500: $scope.$emit("emitErrorReroute", {code : code}); break;
    	default:
    		log.w("No reroute active for error " + code);
    		break;
    }

}]);
