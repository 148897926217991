"use strict";

let Logger = require("../../Logger.js");

let log = new Logger("c:SubjectsOverview", window.logLevels.controller || 4);

angular.module("miw").controller("subjectsOverviewController", ["$scope", "$routeParams", "contentService", function($scope, $routeParams, contentService) {
    "ngInject";

    let unregister = [];

    $scope.type     = $routeParams.type;
    $scope.hasNext  = true;
    $scope.fetching = false;
    $scope.orderby  = "Name";
    $scope.order    = "ASC";
    $scope.limit    = 0;
    $scope.offset   = 0;
    $scope.subjects = null;

    $scope.sorting  = [
        {
            key: "+Name",
            value: "Voornaam oplopend"
        }, {
            key: "-Name",
            value: "Voornaam aflopend"
        }, {
            key: "+Surname",
            value: "Achternaam oplopend"
        }, {
            key: "-Surname",
            value: "Achternaam aflopend"
        }
    ];

    $scope.$emit("emitRouteChanged");
    $scope.$emit("emitRequestNavigation");
    $scope.$emit("emitSetActiveButton", {button: 2});
    $scope.$emit("emitSetActiveItem", {item: $scope.type});
    $scope.$emit("emitShowAvatar", true);
    $scope.$emit("emitSetSearchable", true);

    $scope.$on("$destroy", function() {
        log.fn("destroy", arguments);

        $scope.hasNext  = null;
        $scope.fetching = null;
        $scope.orderby  = null;
        $scope.order    = null;
        $scope.limit    = null;
        $scope.offset   = null;
        $scope.subjects = null;
        $scope.sorting  = null;

        for(let i = unregister - 1; i >= 0; i--)
            unregister[i]();

        unregister = null;

        $scope.delete = function() {};
        $scope.fetch  = function() {};
    });

    unregister.push($scope.$on("emitChangeOrder", function(event, args) {
        log.fn("onEmitChangeOrder", arguments);

        $scope.hasNext  = true;
        $scope.orderby  = args.orderby;
        $scope.order    = args.order;
        $scope.offset   = 0;
        $scope.subjects = null;

        $scope.fetch();
    }));

    $scope.delete = function(subject) {
        log.fn("delete", arguments);

        let subjectName = subject.Name + " " + (subject.MiddleName !== null ? subject.MiddleName + " " : "") + subject.Surname;

        $scope.confirmationPopup("Bevestig actie", "Weet u zeker dat u " + subjectName + " wilt verwijderen?", function(popup) {
            log.fn("confirmationPopup:confirmed", arguments);

            $scope.$emit("emitAnalyticsEvent", {
                event    : "click",
                category : "userAction",
                label    : "Request deletion of subject " + subject.ID
            });

            log.v("Operation confirmed");

            popup.mutate(subjectName + " wordt verwijderd", "", []).withLoader();

            contentService.delete("subject/delete/" + subject.ID, function(response) {
                if(response.data.status === "OK") {
                    log.v("Response OK received.");

                    $scope.subjects.splice($scope.subjects.indexOf(subject), 1);
                    $scope.quickPop(subjectName + " is verwijderd", "", popup);
                } else {
                    log.w("Erroneous response received.", response.data);

                    let title = "Error";
                    let content = response.data.message || response.data || "Request failed";

                    if(response.data.exception !== undefined) {
                        window.console.error(response.data.exception);

                        title   = "Internal Exception";
                        content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                    }

                    $scope.quickPop(title, content, popup);
                }
            }, function(response) {
                $scope.callbackFailure(response, popup);
            });
        }, function(popup) {
            log.fn("confirmationPopup:cancelled", arguments);

            log.v("Operation cancelled");
        });
    };

    $scope.fetch = function() {
        log.fn("fetch", arguments);

        if($scope.fetching || !$scope.hasNext)
            return;

        $scope.fetching = true;

        let queryString = [];

        if($scope.orderby !== null)
            queryString.push("orderby=" + $scope.orderby);

        if($scope.order !== null)
            queryString.push("order=" + $scope.order);

        if($scope.limit !== 0)
            queryString.push("limit=" + $scope.limit);

        if($scope.offset !== 0)
            queryString.push("offset=" + $scope.offset);

        contentService.read("subject/" + $scope.type + (queryString.length > 0 ? "?" + queryString.join("&") : ""), {

        }, function(response) {
            if(response.data.code === 404 && response.data.message === "Query had no results") {
                $scope.hasNext = false;

                return;
            }

            if(response.data.status === "OK") {
                log.v("Response OK received.");

                if($scope.subjects === null)
                    $scope.subjects = [];

                if(Array.isArray(response.data.message)) {
                    // loop over every item because we want to skip identicals.
                    // Possible situations where an identical can occur include, but
                    // are not limmited to:
                    //   filtering and receiving id 2 while the next 10 ids are also
                    //   loaded in the controller.
                    for(let item in response.data.message) {
                        if($scope.subjects.indexOf(response.data.message[item]) === -1)
                            $scope.subjects.push(response.data.message[item]);

                        $scope.offset++;
                    }
                }

                $scope.$emit("emitRequestNext");
            } else {
                log.w("Erroneous response received.", response.data);

                let title = "Error";
                let content = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined) {
                    window.console.error(response.data.exception);

                    title   = "Internal Exception";
                    content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                }

                $scope.quickPop(title, content);
            }

            $scope.fetching = false;
        }, function(response) {
            $scope.fetching = false;
            $scope.hasNext  = false;

            if($scope.subjects === null)
                $scope.subjects = [];

            $scope.callbackFailure(response);
        });
    };

    $scope.fetch();

}]);
