"use strict";

let Logger = require("../Logger.js");

let log = new Logger("s:Content", window.logLevels.service || 4);

// change this to link server on local (unbundled) modules
let server = "server/";

angular.module("miw").service("contentService", ["$http", function($http) {
    "ngInject";

    let config = {
        method  : undefined,
        url     : undefined,
        params  : {},
        data    : undefined,
        headers : {},
    };

    let execute = function(callbackOK, callbackFailure) {
        log.fn("execute", arguments);

        $http(config).then(callbackOK, function(response) {
            if(typeof callbackFailure === "function")
                return callbackFailure(response);

            if(response.data.code === 401)
                return window.location.reload();
        });
    };

    this.create = function(path, data, callbackOK, callbackFailure) {
        log.fn("create", arguments);

        config.method  = "POST";
        config.url     = server + path;
        config.data    = data;
        // config.headers = $httpProvider.defaults.headers.post;

        execute(callbackOK, callbackFailure);
    };
    this.read = function(path, params, callbackOK, callbackFailure) {
        log.fn("read", arguments);

        config.method  = "GET";
        config.url     = server + path;
        config.params  = params;
        // config.headers = $httpProvider.defaults.headers.common;

        execute(callbackOK, callbackFailure);
    };
    this.replace = function(path, data, callbackOK, callbackFailure) {
        log.fn("replace", arguments);

        config.method  = "PUT";
        config.url     = server + path;
        config.data    = data;
        // config.headers = $httpProvider.defaults.headers.put;

        execute(callbackOK, callbackFailure);
    };
    this.modify = function(path, data, callbackOK, callbackFailure) {
        log.fn("modify", arguments);

        config.method  = "PATCH";
        config.url     = server + path;
        config.data    = data;
        // config.headers = $httpProvider.defaults.headers.common;

        execute(callbackOK, callbackFailure);
    };
    this.delete = function(path, callbackOK, callbackFailure) {
        log.fn("delete", arguments);

        config.method  = "DELETE";
        config.url     = server + path;
        // config.headers = $httpProvider.defaults.headers.common;

        execute(callbackOK, callbackFailure);
    };
}]);
