"use strict";

let Logger                    = require("../Logger.js");
let Popup                     = require("../Popup.js");
let PasswordEntropyCalculator = require("../PasswordEntropyCalculator.js");

let log = new Logger("c:Profile", window.logLevels.controller || 4);

angular.module("miw").controller("profileController", ["$scope", "contentService", function($scope, contentService) {
    "ngInject";

    let unregister = [];

    let batchModify = function(popup, operations) {
        log.fn("batchModify", arguments);

        let current = operations !== undefined ? operations.shift() : undefined;

        if(current === undefined)
            return $scope.quickPop("Instellingen opgeslagen", null, popup);

        contentService.modify("profile/updateSettings", current, function(response) {
            if(response.data.status === "OK") {
                log.v("Response OK received.");

                return batchModify(popup, operations);
            } else {
                log.w("Erroneous response received.", response.data);

                let title = "Error";
                let content = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined) {
                    window.console.error(response.data.exception);

                    title   = "Internal Exception";
                    content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                }

                return $scope.quickPop(title, content, popup);
            }
        }, function(response) {
            $scope.callbackFailure(response, popup);
        });
    };

    $scope.page     = "profile";
    $scope.data     = {};
    $scope.saveable = null;
    $scope.password = {
        check     : "",
        className : "",
        original  : "",
        strength  : "",
        token     : "",
    };

    $scope.$emit("emitRouteChanged");
    $scope.$emit("emitRequestNavigation");
    $scope.$emit("emitSetActiveButton", {button: 1});
    $scope.$emit("emitSetActiveItem", {item: 0});
    $scope.$emit("emitShowAvatar", false);
    $scope.$emit("emitSetSearchable", false);

    $scope.$on("$destroy", function() {
        log.fn("destroy", arguments);

        $scope.page     = null;
        $scope.data     = null;
        $scope.saveable = null;
        $scope.password = null;

        for(let i = unregister - 1; i >= 0; i--)
            unregister[i]();

        unregister = null;

        $scope.flipCard = function() {};
        $scope.logout   = function() {};
    });

    unregister.push($scope.$on("emitPasswordSendable", function(event, args) {
        log.fn("onEmitPasswordSendable", arguments);

        let popup = new Popup("busy");
        popup.build("bezig met opslaan")
            .withFader()
            .withLoader()
            .enter();

        $scope.$emit("emitAnalyticsEvent", {
            event    : "resetPassword",
            category : "userAction",
            label    : "Request reset password"
        });

        contentService.modify("profile/updatePassword", {
            credentials: args.password
        }, function(response) {
            $scope.$broadcast("emitReady");

            if(response.data.status === "OK") {
                log.v("Response OK received.");

                popup.mutate("Melding", "Uw wachtwoord is gewijzigd.<br />U zult overnieuw moeten inloggen voor u verder kunt.", [{
                    title  : "OK",
                    listen : [13],
                    action : function() {
                        popup.leave(function() {
                            $scope.logout();
                            popup.delete();
                        }.bind(null, popup));
                    }.bind(null, popup)
                }]);
            } else {
                $scope.$broadcast("emitError");

                if(response.data.exception !== undefined)
                    log.e("An error occured", response.data.exception);

                return $scope.quickPop("Error", response.data.message || response.data || "Request failed", popup);
            }
        }, function(response) {
            $scope.callbackFailure(response, popup);
        });
    }));

    unregister.push($scope.$on("emitSavable", function(event, args) {
        log.fn("onEmitSavable", arguments);

        let popup = new Popup("busy");
        popup.build("bezig met opslaan")
            .withFader()
            .withLoader()
            .enter();

        batchModify(popup, args.operations);
    }));

    unregister.push($scope.$watch("password.original", function(newValue, oldValue) {
        log.fn("watch (password.original)", arguments);

        let score = 0;

        if(newValue !== undefined && newValue.length > 0)
            score = new PasswordEntropyCalculator(newValue).getEntropy();

        switch(true) {
            case score > 90: $scope.password.className = "extreme"; $scope.password.strength = "zeer sterk"; break;
            case score > 80: $scope.password.className = "vstrong"; $scope.password.strength = "sterk";      break;
            case score > 70: $scope.password.className = "strong";  $scope.password.strength = "goed";       break;
            case score > 60: $scope.password.className = "vgood";   $scope.password.strength = "goed";       break;
            case score > 50: $scope.password.className = "good";    $scope.password.strength = "matig";      break;
            case score > 40: $scope.password.className = "agood";   $scope.password.strength = "zwak";       break;
            case score > 30: $scope.password.className = "weak";    $scope.password.strength = "zwak";       break;
            case score > 20: $scope.password.className = "vweak";   $scope.password.strength = "onveilig";   break;
            case score > 10: $scope.password.className = "unsafe";  $scope.password.strength = "onveilig";   break;
            case score > 0:  $scope.password.className = "vunsafe"; $scope.password.strength = "onveilig";   break;
            default:
                 $scope.password.className = "";
                 $scope.password.strength  = "";
                 break;
        }
    }));

    $scope.flipCard = function() {
        log.fn("flipCard", arguments);

        if($scope.page === "profile") {
            $scope.page = "settings";

            return;
        }

        if($scope.page === "settings") {
            $scope.page = "profile";

            return;
        }
    };

    $scope.logout = function() {
        log.fn("logout", arguments);

        $scope.$emit("emitAnalyticsEvent", {
            event    : "logout",
            category : "userAction",
            label    : "Request logout"
        });

        contentService.read("logout", {}, function(response) {
            if(response.data.status === "OK") {
                log.v("Response OK received.");

                window.location.href = window.location.href.split("#")[0];
            } else {
                log.w("Erroneous response received.", response.data);

                let title = "Error";
                let content = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined) {
                    window.console.error(response.data.exception);

                    title   = "Internal Exception";
                    content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                }

                $scope.quickPop(title, content);
            }
        }, $scope.callbackFailure);
    };

    contentService.read("profile", {

    }, function(response) {
        if(response.data.status === "OK") {
            log.v("Response OK received.");

            if(response.data.message.Instance.IsActive)
                response.data.message.Instance.License = response.data.message.Instance.IsExtended ? "Uitgebreid" : "Volledig";

            if(response.data.message.Instance.IsDemo)
                response.data.message.Instance.License = "Demo";

            let endDate = new Date(response.data.message.Instance.EndDate);
            let nowDate = new Date();

            if(!response.data.message.Instance.IsActive || (response.data.message.Instance.IsDemo && nowDate > endDate)) {
                response.data.message.Instance.License = "Verlopen";

                let popup = new Popup("error");
                popup.build("Verlopen licentie", "Uw licentie is verlopen", [])
                    .withFader()
                    .enter();
            }

            $scope.data = response.data.message;

            $scope.saveable = {
                QuestionnaireVisibility : Boolean($scope.data.User.QuestionnaireVisibility),
                "Test" : true,
                "null" : null
            };
        } else {
            log.w("Erroneous response received.", response.data);

            let title = "Error";
            let content = response.data.message || response.data || "Request failed";

            if(response.data.exception !== undefined) {
                window.console.error(response.data.exception);

                title   = "Internal Exception";
                content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
            }

            $scope.quickPop(title, content);
        }
    }, $scope.callbackFailure);

}]);
