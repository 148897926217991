"use strict";

let Logger = require("../Logger.js");
let Popup  = require("../Popup.js");

let log = new Logger("c:Main", window.logLevels.controller || 4);

angular.module("miw").controller("mainController", ["$window", "$scope", "$location", "$analytics", "$anchorScroll", "contentService", function($window, $scope, $location, $analytics, $anchorScroll, contentService) {
    "ngInject";

    let unregister = [];

    let parseLocationName = function($location) {
        log.fn("parseLocation", arguments);

        switch(true) {
            case /profile/.test($location.url())                   : return "Meten is Weten \u00B7 Profielpagina";
            case /cookies/.test($location.url())                   : return "Meten is Weten \u00B7 Cookie beleid";
            case /privacypolicy/.test($location.url())             : return "Meten is Weten \u00B7 Privacy policy";
            case /error/.test($location.url())                     : return "Meten is Weten \u00B7 Reroute";
            case /subjects/.test($location.url())                  : return "Meten is Weten \u00B7 Beheer";
            case /groups/.test($location.url())                    : return "Meten is Weten \u00B7 Groepen";
            case /questionnaires\/report/.test($location.url())    : return "Meten is Weten \u00B7 Rapportage";
            case /questionnaires\/available/.test($location.url()) : return "Meten is Weten \u00B7 Beschikbare vragenlijsten";
            case /questionnaires\/order/.test($location.url())     : return "Meten is Weten \u00B7 Nieuwe vragenlijst bestellen";
            case /questionnaires/.test($location.url())            : return "Meten is Weten \u00B7 Lijsten";
            default:
                return "Meten is Weten";
        }
    };

    let current = "";

    $window.document.title = parseLocationName($location);

    $scope.rerouting       = $scope.pagename === "Reroute";
    $scope.search          = {};
    $scope.navigationItems = null;
    $scope.showAvatar      = true;
    $scope.avatar          = null;
    $scope.hasNav          = true;
    $scope.extended        = false;
    $scope.disallowScroll  = false;
    $scope.searchAble      = false;
    $scope.activeButton    = 0;
    $scope.activeItem      = 0;

    $scope.$on("$destroy", function() {
        log.fn("destroy", arguments);

        $scope.rerouting       = null;
        $scope.search          = null;
        $scope.navigationItems = null;
        $scope.showAvatar      = null;
        $scope.avatar          = null;
        $scope.hasNav          = null;
        $scope.extended        = null;
        $scope.disallowScroll  = null;
        $scope.searchAble      = null;
        $scope.activeButton    = null;
        $scope.activeItem      = null;

        for(let i = unregister - 1; i >= 0; i--)
            unregister[i]();

        unregister = null;

        $scope.confirmationPopup = function() {};
        $scope.quickPop          = function() {};
        $scope.callbackFailure   = function() {};
        $scope.go                = function() {};
        $scope.tryBack           = function() {};
        $scope.scrollTo          = function() {};
    });

    unregister.push($scope.$on("emitHideNavigation", function(event, args) {
        log.fn("onEmitHideNavigation", arguments);

        $scope.hasNav = false;
    }));

    unregister.push($scope.$on("emitRequestNavigation", function(event, args) {
        log.fn("onEmitRequestNavigation", arguments);

        $scope.hasNav = true;

        if($scope.navigationItems === null) {
            contentService.read("navigation", {

            }, function(response) {
                if(response.data.status === "OK") {
                    log.v("Response OK received.");

                    $scope.navigationItems = response.data.message.Types;
                    $scope.avatar          = response.data.message.Avatar;
                    $scope.extended        = response.data.message.Extended;
                } else {
                    log.w("Erroneous response received.", response.data);

                    let title = "Error";
                    let content = response.data.message || response.data || "Request failed";

                    if(response.data.exception !== undefined) {
                        window.console.error(response.data.exception);

                        title   = "Internal Exception";
                        content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                    }

                    $scope.quickPop(title, content);
                }
            }, $scope.callbackFailure);
        }
    }));

    unregister.push($scope.$on("emitShowAvatar", function(event, args) {
        log.fn("onEmitShowAvatar", arguments);

        $scope.showAvatar = args;
    }));

    unregister.push($scope.$on("emitToggleScroll", function(event, args) {
        log.fn("onEmitToggleScroll", arguments);

        $scope.disallowScroll = !$scope.disallowScroll;
    }));

    unregister.push($scope.$on("emitSetActiveButton", function(event, args) {
        log.fn("onEmitSetActiveButton", arguments);

        $scope.activeButton = args.button;
    }));

    unregister.push($scope.$on("emitSetActiveItem", function(event, args) {
        log.fn("onEmitSetActiveItem", arguments);

        $scope.activeItem = args.item;
    }));

    unregister.push($scope.$on("emitSetSearchable", function(event, args) {
        log.fn("onEmitSetSearchable", arguments);

        $scope.searchAble = args;
    }));

    unregister.push($scope.$on("emitAnalyticsEvent", function(event, args) {
        log.fn("onEmitAnalyticsEvent", arguments);

        $analytics.eventTrack(args.event, {category: args.category, label: args.label});
    }));

    unregister.push($scope.$on("emitRouteChanged", function(event, args) {
        log.fn("onEmitRouteChanged", arguments);

        current = $location.$$absUrl;

        $analytics.pageTrack(current);

        $scope.$emit("emitAnalyticsEvent", {
            event    : "browse",
            category : "navigation",
            label    : "Navigated to " + current
        });
    }));

    unregister.push($scope.$on("emitErrorReroute", function(event, args) {
        log.fn("onEmitErrorReroute", arguments);

        $scope.$emit("emitAnalyticsEvent", {
            event    : "error",
            category : "navigation",
            label    : args.code
        });

        let q = "";

        if(current !== undefined && current !== "")
            q = "?lastKnownPage=" + encodeURIComponent(current);

        window.location.assign("errors/" + args.code + ".phtml" + q);
    }));

    $scope.confirmationPopup = function(title, message, actionYes, actionNo, popup) {
        log.fn("confirmationPopup", arguments);

        let actions = [{
            title: "JA",
            action: function() {
                if(typeof actionYes === "function") {
                    actionYes(popup);
                } else {
                    popup.leave(function() {
                        popup.delete();
                    }.bind(null, popup));
                }
            }.bind(null, popup)
        }, {
            title: "NEE",
            action: function() {
                popup.leave(function() {
                    if(typeof actionNo === "function")
                        actionNo(popup);

                    popup.delete();
                }.bind(null, popup));
            }.bind(null, popup)
        }];

        if(popup instanceof Popup)
            return popup.mutate(title, message, actions);

        popup = new Popup("confirmation-popup");

        return popup.build(title, message, actions).withFader().enter();
    };

    $scope.quickPop = function(title, message, popup) {
        log.fn("quickPop", arguments);

        let actions = [{
            title  : "OK",
            listen : [13],
            action : function() {
                popup.leave(function() {
                    popup.delete();
                }.bind(null, popup));
            }.bind(null, popup)
        }];

        if(popup instanceof Popup)
            return popup.mutate(title, message, actions);

        popup = new Popup("quick-popup");

        return popup.build(title, message, actions).withFader().enter();
    };

    $scope.callbackFailure = function(response, popup) {
        log.fn("callbackFailure", arguments);

        if(response.data.code === 401)
            return window.location.reload();

        if(response.status === undefined && response.statusText === undefined)
            return log.e("An error occured while status and statusText where undefined.", response);

        if(response.data.code === 404 && response.data.message === "Query had no results")
            return;

        let title   = "Error " + response.status;
        let message = response.data.message !== undefined ? response.data.message : response.statusText;
        let actions = [{
            title  : "OK",
            listen : [13],
            action : function() {
                popup.leave(function() {
                    popup.delete();
                }.bind(null, popup));
            }.bind(null, popup)
        }];

        if(popup instanceof Popup)
            return popup.mutate(title, message, actions);

        popup = new Popup("error-popup");

        return popup.build(title, message, actions).withFader().enter();
    };

    $scope.go = function(path, name) {
        log.fn("go", arguments);

        $location.path(path);

        if(name === undefined) {
            $scope.pagename = parseLocationName($location, name);
        } else {
            $scope.pagename = name;
        }

        $scope.rerouting = $scope.pagename === "Reroute";
    };

    $scope.tryBack = function(alternative) {
        log.fn("tryBack", arguments);

        if(window.history.length > 0)
            return window.history.back();

        if(alternative.length > 0)
            return $scope.go(alternative);

        return log.w("Unable to go back in history questionnaire and no alternative was supplied.");
    };

    $scope.scrollTo = function(id) {
        log.fn("scrollTo", arguments);

        $anchorScroll(id);
    };

}]);
