"use strict";

let Logger = require("../Logger.js");

let log = new Logger("d:QuestionnaireWizard", window.logLevels.directives || 4);

angular.module("miw").directive("questionnaireWizard", ["$compile", "$rootScope", function($compile, $rootScope) {
    "ngInject";

    return {
        restrict : "E",
        templateUrl: "script/templates/questionnaire-wizard/main.html",
        scope: {
            wizardSteps: "=ngModel"
        },

        link: function(scope, element, attrs) {
            log.fn("link", arguments);

            scope.data = {
                title                   : attrs.title,
                questionnaireIdentifier : (attrs.questionnaireIdentifier !== undefined && attrs.questionnaireIdentifier !== "") ? attrs.questionnaireIdentifier : undefined,
                exclude                 : (attrs.inviteeExclude !== undefined && attrs.inviteeExclude !== "") ? JSON.parse(attrs.inviteeExclude) : [],
                currentStep             : 0,
                questionnaire           : 0,
                subjects                : [],
                invitees                : [],
                message: {
                    subject  : undefined,
                    invitees : undefined
                },
                parsed: {
                    questionnaire : undefined,
                    invitees      : undefined,
                    subjects      : undefined
                }
            };

            scope.pagerOffset = "-" + scope.data.currentStep * 100 + "%";

            for(let step in scope.wizardSteps) {
                if(["questionnaire-select", "subject-select", "invitee-select", "personalise-data", "check-data"].indexOf(scope.wizardSteps[step]) !== -1)
                    angular.element(angular.element(element.children()[0]).children()[0]).append($compile(angular.element("<div class=\"" + scope.wizardSteps[step] + "\" ng-model=\"data\" data-extended=\"" + attrs.extended + "\" data-step=\"" + (parseInt(step) + 1) + "\" data-steps=\"" + scope.wizardSteps.length + "\"></div>"))(scope));
            }


            if(scope.wizardSteps.indexOf("subject-select") === -1 && attrs.subject !== undefined) {
                let subject = JSON.parse(attrs.subject);

                if(subject.ID !== undefined) {
                    scope.data.subjects        = [subject.ID];
                    scope.data.invitees        = [subject.ID];
                    scope.data.parsed.subjects = [subject.Name + " " + (subject.MiddleName !== null ? subject.MiddleName + " " : "") + subject.Surname];
                }
            }

            scope.$on("emitWizardPrevious", function(event, args) {
                log.fn("onEmitWizardPrevious", arguments);

                if(scope.data.currentStep === 0) {
                    $rootScope.$broadcast("emitStopWizard", {element: element});
                } else {
                    scope.data.currentStep--;
                    scope.pagerOffset = "-" + scope.data.currentStep * 100 + "%";
                }
            });

            scope.$on("emitWizardNext", function(event, args) {
                log.fn("onEmitWizardNext", arguments);

                if(scope.data.currentStep === scope.wizardSteps.length - 1) {
                    $rootScope.$broadcast("emitSendWizard", {
                        element       : element,
                        state         : scope.data.questionnaireIdentifier === undefined ? "create" : "addInvitees",
                        questionnaire : scope.data.questionnaireIdentifier,
                        data: {
                            questionnaire : parseInt(scope.data.questionnaire),
                            subjects      : scope.data.subjects,
                            invitees      : scope.data.invitees,
                            message       : scope.data.message
                        }
                    });
                } else {
                    scope.data.currentStep++;
                    scope.pagerOffset = "-" + scope.data.currentStep * 100 + "%";

                    log.d("Parsed data:", {
                        title                   : scope.data.title,
                        questionnaireIdentifier : scope.data.questionnaireIdentifier,
                        exclude                 : scope.data.exclude,
                        currentStep             : scope.data.currentStep,
                        questionnaire           : scope.data.questionnaire,
                        subjects                : scope.data.subjects,
                        invitees                : scope.data.invitees,
                        message                 : scope.data.message,
                        parsed                  : scope.data.parsed
                    });
                }
            });

            log.d("Wizard initiated with scope", scope);
        }
    };
}]);
