"use strict";

/* jshint node   : true  */
/* jshint unused : false */

let Popup = function(id) {
    let global = typeof window !== "undefined" ? window : this;
    let el = null;
    let fr = null;

    this.retrieve = function() {
        if(id instanceof HTMLElement)
            el = id;

        if(id.substring(0, 1) === ":")
            el = global.document.getElementById(id.substring(1, id.length));

        if(el instanceof HTMLElement)
            return this;

        el = null;

        return this;
    };

    this.build = function(title, content, buttons, classNames) {
        el    = global.document.createElement("div");
        el.id = id;

        el.classList.add("popup");

        for(let c in classNames)
            el.classList.add(classNames[c]);

        let titleEl   = global.document.createElement("h3");
        let contentEl = global.document.createElement("div");
        let textEl    = global.document.createElement("p");
        let keyCodes  = [];

        titleEl.innerHTML = title;
        textEl.innerHTML  = content;

        contentEl.appendChild(textEl);

        el.appendChild(titleEl);
        el.appendChild(contentEl);

        for(let b in buttons) {
            let button = global.document.createElement("button");

            button.innerHTML = buttons[b].title;
            button.onclick   = buttons[b].action;

            if(buttons[b].listen !== undefined) {
                for(let c in buttons[b].listen)
                    keyCodes[buttons[b].listen[c]] = button;
            }

            el.appendChild(button);
        }

        global.document.body.appendChild(el);

        el.dataset.marginTop   = "-" + (el.offsetHeight / 2) + "px";
        el.style.marginLeft    = "-" + (el.offsetWidth  / 2) + "px";
        // contentEl.style.height = el.offsetHeight - 119 + "px"; // ???

        el.focus();

        global.onkeyup = function(e) {
            let key = e.keyCode ? e.keyCode : e.which;

            if(keyCodes[key] instanceof HTMLElement)
                keyCodes[key].click();
        };

        return this;
    };

    this.mutate = function(title, content, buttons, classNames) {
        el.class     = "";
        el.innerHTML = "";

        el.classList.add("popup");

        for(let c in classNames)
            el.classList.add(classNames[c]);

        let titleEl   = global.document.createElement("h3");
        let contentEl = global.document.createElement("div");
        let textEl    = global.document.createElement("p");
        let keyCodes  = [];

        titleEl.innerHTML = title;
        textEl.innerHTML  = content;

        contentEl.appendChild(textEl);

        el.appendChild(titleEl);
        el.appendChild(contentEl);

        for(let b in buttons) {
            let button = global.document.createElement("button");

            button.innerHTML = buttons[b].title;
            button.onclick   = buttons[b].action;

            if(buttons[b].listen !== undefined) {
                for(let c in buttons[b].listen)
                    keyCodes[buttons[b].listen[c]] = button;
            }

            el.appendChild(button);
        }

        el.dataset.marginTop   = "-" + (el.offsetHeight / 2) + "px";
        el.style.marginLeft    = "-" + (el.offsetWidth  / 2) + "px";
        contentEl.style.height = el.offsetHeight - 119 + "px";

        global.onkeyup = function(e) {
            let key = e.keyCode ? e.keyCode : e.which;

            if(keyCodes[key] instanceof HTMLElement)
                keyCodes[key].click();
        };

        return this;
    };

    this.withFader = function() {
        fr = global.document.createElement("div");

        fr.id = "fader";

        global.document.body.appendChild(fr);

        return this;
    };

    this.withLoader = function() {
        let loader = el.getElementsByTagName("div")[0];

        loader.innerHTML = "";
        loader.classList.add("loader");
        el.appendChild(loader);

        return this;
    };

    this.enter = function() {
        global.setTimeout(function() {
            if(fr !== null)
                fr.style.opacity = 1;

            el.style.marginBottom = 0;
            el.style.marginTop    = el.dataset.marginTop !== undefined ? el.dataset.marginTop : 0;
        }, 50);
    };

    this.leave = function(callback) {
        el.style.marginBottom = "";
        el.style.marginTop    = "";

        global.setTimeout(function() {
            if(fr === null)
                return callback();

            fr.style.opacity = 0;

            global.setTimeout(function() {
                if(typeof callback === "function")
                    return callback();
            }, 500);
        }, 50);
    };

    this.delete = function() {
        el.parentNode.removeChild(el);

        if(fr !== null)
            fr.parentNode.removeChild(fr);
    };
};

module.exports = Popup;
