"use strict";

let Logger = require("../Logger.js");

let log = new Logger("d:PasswordButton", window.logLevels.directives || 4);

angular.module("miw").directive("passwordButton", ["$rootScope", function($rootScope) {
    "ngInject";

    return {
        restrict : "C",
        scope: {
            password : "=ngModel"
        },

        link: function(scope, element, attrs) {
            log.fn("link", arguments);

            let original = scope.password !== undefined ? scope.password.original || undefined : undefined;
            let check    = scope.password !== undefined ? scope.password.check    || undefined : undefined;

            scope.$watch("password", function(newValue, oldValue) {
                log.fn("watch (password)", arguments);

                if(newValue !== undefined) {
                    original = newValue.original !== "" ? newValue.original : undefined;
                    check    = newValue.check    !== "" ? newValue.check    : undefined;
                }

                let disabled = original === undefined || check === undefined || newValue.original.length < 6 || original !== check;

                attrs.$set("disabled", disabled);
            }, true);

            element.bind("click", function(event) {
                log.fn("click", arguments);

                if(!event.isTrusted)
                    return log.w("Please click the element yourself!");

                attrs.$set("disabled", true);

                $rootScope.$broadcast("emitPasswordSendable", {e: event, password: btoa(original)});
            });
        }
    };
}]);
