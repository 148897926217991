"use strict";

let Logger = require("../Logger.js");

let log = new Logger("d:SaveButton", window.logLevels.directives || 4);

angular.module("miw").directive("saveButton", ["$rootScope", function($rootScope) {
    "ngInject";

    return {
        restrict : "C",
        scope: {
            saveable : "=ngModel"
        },

        link: function(scope, element, attrs) {
            log.fn("link", arguments);

            let original = null;
            let changed  = {};

            scope.$watch("saveable", function(newValue, oldValue) {
                log.fn("watch (saveable)", arguments);

                if(original === null)
                    original = angular.copy(scope.saveable);

                if(newValue !== undefined) {
                    for(let item in newValue) {
                        if(newValue[item] !== original[item])
                            changed[item] = newValue[item];
                    }
                }

                attrs.$set("disabled", Object.keys(changed).length === 0);
            }, true);

            scope.$on("emitReady", function(event, args) {
                log.fn("onEmitReady", arguments);

                attrs.$set("disabled", Object.keys(changed).length === 0);
            });

            scope.$on("emitError", function(event, args) {
                log.fn("onEmitError", arguments);

                attrs.$set("disabled", true);
            });

            element.bind("click", function(event) {
                log.fn("click", arguments);

                if(!event.isTrusted)
                    return log.w("Please click the element yourself!");

                attrs.$set("disabled", true);

                let operations = [];

                for(let item in changed) {
                    if(changed[item] === "" || changed[item] === null || changed[item] === undefined) {
                        operations.push({
                            operation : "REMOVE",
                            field     : item
                        });
                    } else {
                        operations.push({
                            operation : "REPLACE",
                            field     : item,
                            value     : (changed[item] === true ? 1 : (changed[item] === false ? 0 : changed[item]))
                        });
                    }
                }

                $rootScope.$broadcast("emitSavable", {e: event, operations: operations});
            });
        }
    };
}]);
