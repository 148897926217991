"use strict";

let Logger = require("../../Logger.js");

let log = new Logger("d:CheckData", window.logLevels.directives || 4);

angular.module("miw").directive("checkData", ["$compile", "$rootScope", function($compile, $rootScope) {
    "ngInject";

    return {
        restrict : "C",
        templateUrl: "script/templates/questionnaire-wizard/check-data.html",
        scope: {
            data : "=ngModel"
        },

        link: function(scope, element, attrs) {
            log.fn("link", arguments);

            scope.step  = attrs.step;
            scope.steps = attrs.steps;

            scope.wizardPrevious = function() {
                log.fn("wizardPrevious", arguments);

                scope.$emit("emitWizardPrevious");
            };

            scope.wizardNext = function() {
                log.fn("wizardNext", arguments);

                scope.$emit("emitWizardNext");
            };
        }
    };
}]);
