"use strict";

let Logger = require("../../Logger.js");

let log = new Logger("d:QuestionnaireSelect", window.logLevels.directives || 4);

angular.module("miw").directive("questionnaireSelect", ["$compile", "$rootScope", "contentService", function($compile, $rootScope, contentService) {
    "ngInject";

    return {
        restrict : "C",
        templateUrl: "script/templates/questionnaire-wizard/questionnaire-select.html",
        scope: {
            data: "=ngModel"
        },

        link: function(scope, element, attrs) {
            log.fn("link", arguments);

            scope.step           = attrs.step;
            scope.steps          = attrs.steps;
            scope.extended       = attrs.extended === "true";
            scope.blockNext      = true;
            scope.questionnaires = [];
            scope.preview        = {
                name      : undefined,
                domains   : undefined,
                questions : undefined,
                contents  : undefined
            };

            scope.data.questionnaire = undefined;

            // since this is always step one, just execute the request.
            if(scope.questionnaires.length === 0)
                contentService.read("questionnaire/", {}, function(response) {
                    if(response.data.status === "OK") {
                        log.v("Response OK received.");

                        scope.questionnaires = response.data.message;
                    } else {
                        log.w("Erroneous response received.", response.data);
                    }
                });

            scope.wizardPrevious = function() {
                log.fn("wizardPrevious", arguments);

                scope.$emit("emitWizardPrevious");
            };

            scope.wizardNext = function() {
                log.fn("wizardNext", arguments);

                scope.$emit("emitWizardNext");
            };

            scope.$watch("data.questionnaire", function(newValue, oldValue) {
                log.fn("watch (data.questionnaire)", arguments);

                if(!isNaN(parseFloat(newValue)) && isFinite(newValue)) {
                    for(let questionnaire in scope.questionnaires) {
                        if(scope.questionnaires[questionnaire].ID === parseInt(newValue)) {
                            scope.data.parsed.questionnaire = scope.questionnaires[questionnaire].Name;
                            scope.blockNext                 = false;

                            scope.preview = {
                                name      : scope.questionnaires[questionnaire].Name,
                                domains   : scope.questionnaires[questionnaire].Domains,
                                questions : scope.questionnaires[questionnaire].NumQuestions,
                                contents  : scope.questionnaires[questionnaire].Questions
                            };
                        }
                    }
                }
            }, true);
        }
    };
}]);
