"use strict";

let Logger = require("../../Logger.js");

let log = new Logger("c:QuestionnairesOrder", window.logLevels.controller || 4);

angular.module("miw").controller("questionnairesOrderController", ["$scope", "$routeParams", "contentService", function($scope, $routeParams, contentService) {
    "ngInject";

    $scope.$emit("emitRouteChanged");
    $scope.$emit("emitRequestNavigation");
    $scope.$emit("emitSetActiveButton", {button: 4});
    $scope.$emit("emitSetActiveItem", {item: "L3"});
    $scope.$emit("emitShowAvatar", true);
    $scope.$emit("emitSetSearchable", false);

}]);
