"use strict";

let Logger                    = require("../Logger.js");
let PasswordEntropyCalculator = require("../PasswordEntropyCalculator.js");

let log = new Logger("c:Login", window.logLevels.controller || 4);

angular.module("miw").controller("loginController", ["$scope", "contentService", function($scope, contentService) {
    "ngInject";

    let emitOnCallbackFailure = function(response) {
        $scope.$broadcast("emitReady");
        $scope.$broadcast("emitError");

        canSend = false;

        if(response.status === undefined && response.statusText === undefined)
            return log.e("An error occured while status and statusText where undefined.", response);

        if(response.data.code === 404 && response.data.message === "Query had no results")
            return;

        $scope.lastError = response.data.message !== undefined ? response.data.message : response.statusText;
    };

    let canSend     = true;
    let messageLink = null;

    $scope.lastError = null;
    $scope.message   = null;
    $scope.card      = null;

    $scope.password = {
        check     : "",
        className : "",
        original  : "",
        strength  : "",
        token     : "",
    };

    $scope.credentials = {
        username : "",
        password : ""
    };

    $scope.$watch("password.original", function(newValue, oldValue) {
        log.fn("watch (password.original)", arguments);

        let score = 0;

        if(newValue !== undefined && newValue.length > 0)
            score = new PasswordEntropyCalculator(newValue).getEntropy();

        switch(true) {
            case score > 90: $scope.password.className = "extreme"; $scope.password.strength = "zeer sterk"; break;
            case score > 80: $scope.password.className = "vstrong"; $scope.password.strength = "sterk";      break;
            case score > 70: $scope.password.className = "strong";  $scope.password.strength = "goed";       break;
            case score > 60: $scope.password.className = "vgood";   $scope.password.strength = "goed";       break;
            case score > 50: $scope.password.className = "good";    $scope.password.strength = "matig";      break;
            case score > 40: $scope.password.className = "agood";   $scope.password.strength = "zwak";       break;
            case score > 30: $scope.password.className = "weak";    $scope.password.strength = "zwak";       break;
            case score > 20: $scope.password.className = "vweak";   $scope.password.strength = "onveilig";   break;
            case score > 10: $scope.password.className = "unsafe";  $scope.password.strength = "onveilig";   break;
            case score > 0:  $scope.password.className = "vunsafe"; $scope.password.strength = "onveilig";   break;
            default:
                 $scope.password.className = "";
                 $scope.password.strength  = "";
                 break;
        }
    });

    $scope.back = function() {
        $scope.$broadcast("emitReset");

        $scope.card              = "login";
        $scope.lastError         = null;
        $scope.message           = null;
        $scope.password.check    = "";
        $scope.password.original = "";
    };

    $scope.linkback = function() {
        if(messageLink !== null && messageLink !== false)
            window.open("//www." + messageLink, "_blank");

        messageLink = null;

        $scope.back();
    };

    $scope.forgotten = function() {
        $scope.$broadcast("emitReset");

        $scope.card                 = "forgotten";
        $scope.credentials.password = "";
        $scope.lastError            = null;
        $scope.message              = null;
    };

    $scope.refresh = function() {
        if($scope.lastError !== null)
            window.location.reload();
    };

    $scope.$on("emitPasswordSendable", function(event, args) {
        log.fn("onEmitPasswordSendable", arguments);

        let credentials = $scope.password.token + "//" + args.password + "//" + Date.now();

        log.d("password update pending", {credentials: credentials});

        $scope.$emit("emitAnalyticsEvent", {
            event    : "resetPassword",
            category : "userAction",
            label    : "Request reset password"
        });

        contentService.create("resetpassword", {
            credentials: credentials
        }, function(response) {
            $scope.$broadcast("emitReady");

            if(response.data.status === "OK") {
                log.v("Response OK received.");

                $scope.back();
            } else {
                $scope.$broadcast("emitError");

                $scope.lastError = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined)
                    log.e("An error occured", response.data.exception);

                canSend = false;
            }
        }, emitOnCallbackFailure);
    });

    $scope.$on("emitNext", function(event, args) {
        log.fn("onEmitNext", arguments);

        if(!canSend)
            return;

        if($scope.credentials.username !== "" && $scope.credentials.password !== "")
            $scope.$broadcast("emitSendable");

        if($scope.password.original !== "" && $scope.password.check !== "" && $scope.password.original.length > 6 && $scope.password.original === $scope.password.check)
            $scope.$broadcast("emitPasswordSendable");
    });

    $scope.$on("emitSendable", function(event, args) {
        log.fn("onEmitSendable", arguments);

        $scope.$emit("emitAnalyticsEvent", {
            event    : "login",
            category : "userAction",
            label    : "Request login"
        });

        contentService.create("login", {
            credentials: btoa(JSON.stringify($scope.credentials))
        }, function(response) {
            $scope.$broadcast("emitReady");

            if(response.data.status === "OK") {
                log.v("Response OK received.");

                if(response.data.message === "Logged in successfully") {
                    window.location.reload();
                } else {
                    $scope.$broadcast("emitFailure");
                }
            } else {
                $scope.$broadcast("emitError");

                $scope.lastError = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined)
                    log.e("An error occured", response.data.exception);

                canSend = false;
            }
        }, emitOnCallbackFailure);
    });

    $scope.$on("emitForgottenSendable", function(event, args) {
        log.fn("onEmitForgottenSendable", arguments);

        $scope.$emit("emitAnalyticsEvent", {
            event    : "forgottenPassword",
            category : "userAction",
            label    : "Request forgotten password"
        });

        contentService.create("forgottenpassword", {
            username: $scope.credentials.username
        }, function(response) {
            $scope.$broadcast("emitReady");

            if(response.data.status === "OK") {
                log.v("Response OK received.");

                $scope.message = response.data.message.message;

                messageLink = response.data.message.domain;
            } else {
                $scope.$broadcast("emitError");

                $scope.lastError = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined)
                    log.e("An error occured", response.data.exception);

                canSend = false;
            }
        }, emitOnCallbackFailure);
    });
}]);
