"use strict";

let Logger = require("../../Logger.js");

let log = new Logger("c:QuestionnairesOverview", window.logLevels.controller || 4);

angular.module("miw").controller("questionnairesOverviewController", ["$scope", "$compile", "contentService", function($scope, $compile, contentService) {
    "ngInject";

    let unregister = [];

    $scope.hasNext  = true;
    $scope.fetching = false;
    $scope.orderby  = "QuestionnaireIdentifier";
    $scope.order    = "DESC";
    $scope.limit    = 0;
    $scope.offset   = 0;
    $scope.questionnaires    = null;

    $scope.sorting  = [
        {
            key: "+QuestionnaireIdentifier",
            value: "Datum oplopend"
        }, {
            key: "-QuestionnaireIdentifier",
            value: "Datum aflopend"
        }
    ];

    $scope.editWizardSteps = [
        "invitee-select",
        "personalise-data",
        "check-data",
    ];

    $scope.$emit("emitRouteChanged");
    $scope.$emit("emitRequestNavigation");
    $scope.$emit("emitSetActiveButton", {button: 4});
    $scope.$emit("emitSetActiveItem", {item: "L1"});
    $scope.$emit("emitShowAvatar", true);
    $scope.$emit("emitSetSearchable", true);

    $scope.$on("$destroy", function() {
        log.fn("destroy", arguments);

        $scope.hasNext         = null;
        $scope.fetching        = null;
        $scope.orderby         = null;
        $scope.order           = null;
        $scope.limit           = null;
        $scope.offset          = null;
        $scope.questionnaires  = null;
        $scope.sorting         = null;
        $scope.editWizardSteps = null;

        for(let i = unregister - 1; i >= 0; i--)
            unregister[i]();

        unregister = null;

        $scope.startWizard         = function() {};
        $scope.stopWizard          = function() {};
        $scope.remind              = function() {};
        $scope.editQuestionnaire   = function() {};
        $scope.deleteQuestionnaire = function() {};
        $scope.fetch               = function() {};
    });

    unregister.push($scope.$on("emitChangeOrder", function(event, args) {
        log.fn("onEmitChangeOrder", arguments);

        $scope.hasNext        = true;
        $scope.orderby        = args.orderby;
        $scope.order          = args.order;
        $scope.offset         = 0;
        $scope.questionnaires = null;

        $scope.fetch();
    }));

    unregister.push($scope.$on("emitStopWizard", function(event, args) {
        log.fn("onEmitStopWizard", arguments);

        $scope.stopWizard(args.element);
    }));

    unregister.push($scope.$on("emitSendWizard", function(event, args) {
        log.fn("onEmitSendWizard", arguments);

        $scope.stopWizard(args.element);

        log.d("sending data in " + args.state + " mode", args.data);

        $scope.$emit("emitAnalyticsEvent", {
            event    : args.state,
            category : "userAction",
            label    : "Applying action " + args.state + " on questionnaireController"
        });

        contentService.create("questionnaire/" + args.state + "/" + (args.questionnaire !== undefined ? args.questionnaire : ""), args.data, function(response) {
            if(response.data.status === "OK") {
                log.v("Response OK received.");

                // @todo: refresh the data on screen
            } else {
                log.w("Erroneous response received.", response.data);

                let title = "Error";
                let content = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined) {
                    window.console.error(response.data.exception);

                    title   = "Internal Exception";
                    content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                }

                $scope.quickPop(title, content);
            }
        }, $scope.callbackFailure);
    }));

    $scope.startWizard = function() {
        let wizard   = angular.element("<questionnaire-wizard ng-model=\"wizardSteps\" data-title=\"Nieuwe lijst uitzetten\" data-subject=\"{{data.Subject}}\"></questionnaire-wizard>");
        let wizardEl = $compile(wizard)($scope);

        $scope.$emit("emitToggleScroll");

        angular.element(document.body).append(wizardEl);
    };

    $scope.stopWizard = function(wizardEl) {
        $scope.$emit("emitToggleScroll");

        angular.element(wizardEl).remove();
    };

    $scope.remind = function(questionnaire) {
        log.fn("remind", arguments);

        $scope.$emit("emitAnalyticsEvent", {
            event    : "remind",
            category : "userAction",
            label    : "Request remind of questionnaire " + questionnaire.QuestionnaireIdentifier
        });

        contentService.create("questionnaire/remind/" + questionnaire.QuestionnaireIdentifier, {

        }, function(response) {
            if(response.data.status === "OK") {
                log.v("Response OK received.");

                $scope.quickPop("Melding", response.data.message.Reminders + " " + (parseInt(response.data.message.Reminders) === 1 ? "herinnering" : "herinneringen") + " verstuurd.");
            } else {
                log.w("Erroneous response received.", response.data);

                let title = "Error";
                let content = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined) {
                    window.console.error(response.data.exception);

                    title   = "Internal Exception";
                    content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                }

                $scope.quickPop(title, content);
            }
        }, $scope.callbackFailure);
    };

    $scope.editQuestionnaire = function(questionnaire) {
        log.fn("editQuestionnaire", arguments);

        let questionnaireIdentifier = questionnaire.QuestionnaireIdentifier;
        let inviteeExclude          = JSON.stringify(questionnaire.Data.Invited.Raw);

        log.d("Binding data to wizard", {
            questionnaireIdentifier: questionnaireIdentifier,
            inviteeExclude: inviteeExclude
        });

        let wizard   = angular.element("<questionnaire-wizard ng-model=\"editWizardSteps\" data-title=\"Lijst bewerken\" data-questionnaire-identifier=\"" + questionnaireIdentifier + "\" data-invitee-exclude=\"" + inviteeExclude + "\"></questionnaire-wizard>");
        let wizardEl = $compile(wizard)($scope);

        $scope.$emit("emitToggleScroll");

        angular.element(document.body).append(wizardEl);
    };

    $scope.deleteQuestionnaire = function(questionnaire) {
        log.fn("deleteQuestionnaire", arguments);

        $scope.confirmationPopup("Bevestig actie", "Weet u zeker dat u deze lijst wilt verwijderen?", function(popup) {
            log.fn("confirmationPopup:confirmed", arguments);

            log.v("Operation confirmed");

            $scope.$emit("emitAnalyticsEvent", {
                event    : "delete",
                category : "userAction",
                label    : "Request deleteion of questionnaire " + questionnaire.QuestionnaireIdentifier
            });

            popup.mutate("Lijst wordt verwijderd", "", []).withLoader();

            contentService.delete("questionnaire/delete/" + questionnaire.QuestionnaireIdentifier, function(response) {
                if(response.data.status === "OK") {
                    log.v("Response OK received.");

                    $scope.questionnaires.splice($scope.questionnaires.indexOf(questionnaire), 1);
                    $scope.quickPop("Lijst is verwijderd", "", popup);
                } else {
                    log.w("Erroneous response received.", response.data);

                    let title = "Error";
                    let content = response.data.message || response.data || "Request failed";

                    if(response.data.exception !== undefined) {
                        window.console.error(response.data.exception);

                        title   = "Internal Exception";
                        content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                    }

                    $scope.quickPop(title, content, popup);
                }
            }, function(response) {
                $scope.callbackFailure(response, popup);
            });
        }, function(popup) {
            log.fn("confirmationPopup:cancelled", arguments);

            log.v("Operation cancelled");
        });
    };

    $scope.fetch = function() {
        log.fn("fetch", arguments);

        if($scope.fetching || !$scope.hasNext)
            return;

        $scope.fetching = true;

        let queryString = [];

        if($scope.orderby !== null)
            queryString.push("orderby=Answer." + $scope.orderby);

        if($scope.order !== null)
            queryString.push("order=" + $scope.order);

        if($scope.limit !== 0)
            queryString.push("limit=" + $scope.limit);

        if($scope.offset !== 0)
            queryString.push("offset=" + $scope.offset);

        contentService.read("answer/" + (queryString.length > 0 ? "?" + queryString.join("&") : ""), {

        }, function(response) {
            if(response.data.code === 404 && response.data.message === "Query had no results") {
                $scope.hasNext = false;

                return;
            }

            if(response.data.status === "OK") {
                log.v("Response OK received.");

                if($scope.questionnaires === null)
                    $scope.questionnaires = [];

                if(Array.isArray(response.data.message.Questionnaires)) {
                    // loop over every item because we want to skip identicals.
                    // Possible situations where an identical can occur include, but
                    // are not limmited to:
                    //   filtering and receiving id 2 while the next 10 ids are also
                    //   loaded in the controller.
                    for(let item in response.data.message.Questionnaires) {
                        if($scope.questionnaires.indexOf(response.data.message.Questionnaires[item]) === -1)
                            $scope.questionnaires.push(response.data.message.Questionnaires[item]);
                    }
                }

                $scope.offset = response.data.message.Offset;

                $scope.$emit("emitRequestNext");
            } else {
                log.w("Erroneous response received.", response.data);

                let title = "Error";
                let content = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined) {
                    window.console.error(response.data.exception);

                    title   = "Internal Exception";
                    content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                }

                $scope.quickPop(title, content);
            }

            $scope.fetching = false;
        }, function(response) {
            $scope.fetching = false;
            $scope.hasNext  = false;

            if($scope.questionnaires === null)
                $scope.questionnaires = [];

            $scope.callbackFailure(response);
        });
    };

    $scope.fetch();

}]);
