"use strict";

let Logger = require("../../Logger.js");

let log = new Logger("c:SubjectsProfile", window.logLevels.controller || 4);

angular.module("miw").controller("subjectsProfileController", ["$window", "$scope", "$routeParams", "$compile", "$timeout", "contentService", function($window, $scope, $routeParams, $compile, $timeout, contentService) {
    "ngInject";

    let unregister = [];

    $scope.id           = $routeParams.id;
    $scope.data         = null;
    $scope.editMode     = false;
    $scope.compareCheck = null;
    $scope.compareArray = [];

    $scope.wizardSteps = [
        "questionnaire-select",
        "invitee-select",
        "personalise-data",
        "check-data"
    ];

    $scope.editWizardSteps = [
        "invitee-select",
        "personalise-data",
        "check-data",
    ];

    $scope.$emit("emitRouteChanged");
    $scope.$emit("emitRequestNavigation");
    $scope.$emit("emitSetActiveButton", {button: 2});
    $scope.$emit("emitShowAvatar", true);
    $scope.$emit("emitSetSearchable", false);

    $scope.$on("$destroy", function() {
        log.fn("destroy", arguments);

        $scope.id              = null;
        $scope.data            = null;
        $scope.editMode        = null;
        $scope.compareCheck    = null;
        $scope.compareArray    = null;
        $scope.wizardSteps     = null;
        $scope.editWizardSteps = null;

        for(let i = unregister - 1; i >= 0; i--)
            unregister[i]();

        unregister = null;

        $scope.flipcard              = function() {};
        $scope.save                  = function() {};
        $scope.startWizard           = function() {};
        $scope.stopWizard            = function() {};
        $scope.compareQuestionnaires = function() {};
        $scope.downloadPDF           = function() {};
        $scope.toggleCompare         = function() {};
        $scope.remind                = function() {};
        $scope.editQuestionnaire     = function() {};
        $scope.deleteQuestionnaire   = function() {};
        $scope.refreshData           = function() {};
    });

    unregister.push($scope.$on("emitStopWizard", function(event, args) {
        log.fn("onEmitStopWizard", arguments);

        $scope.stopWizard(args.element);
    }));

    unregister.push($scope.$on("emitSendWizard", function(event, args) {
        log.fn("onEmitSendWizard", arguments);

        $scope.stopWizard(args.element);

        log.d("sending data in " + args.state + " mode", args.data);

        $scope.$emit("emitAnalyticsEvent", {
            event    : args.state,
            category : "userAction",
            label    : "Applying action " + args.state + " on questionnaireController"
        });

        contentService.create("questionnaire/" + args.state + "/" + (args.questionnaire !== undefined ? args.questionnaire : ""), args.data, function(response) {
            if(response.data.status === "OK") {
                log.v("Response OK received.");

                $scope.refreshData();
            } else {
                log.w("Erroneous response received.", response.data);

                let title = "Error";
                let content = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined) {
                    window.console.error(response.data.exception);

                    title   = "Internal Exception";
                    content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                }

                $scope.quickPop(title, content);
            }
        }, $scope.callbackFailure);
    }));

    $scope.flipcard = function() {
        log.fn("flipcard", arguments);

        $scope.editMode = "update";
    };

    $scope.save = function() {
        log.fn("save", arguments);

        let data = {
            Type       : $scope.data.Subject.Type,
            Name       : $scope.data.Subject.Name,
            MiddleName : $scope.data.Subject.MiddleName,
            Surname    : $scope.data.Subject.Surname,
            Email      : $scope.data.Subject.Email
        };

        let callbackSuccess = function(response) {
            if(response.data.status === "OK") {
                log.v("Response OK received.");

                if(response.data.message === null)
                    return $scope.quickPop("Oeps", "Er is iets mis gegaan.");

                if(Array.isArray(response.data.message))
                    return $scope.go("subjects/profile/" + response.data.message[0].ID);

                $scope.refreshData();
                $scope.editMode = false;
            } else {
                log.w("Erroneous response received.", response.data);

                let title = "Error";
                let content = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined) {
                    window.console.error(response.data.exception);

                    title   = "Internal Exception";
                    content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                }

                $scope.quickPop(title, content);
            }
        };

        switch($scope.editMode) {
            case "update":
                log.v("Updating subject");

                $scope.$emit("emitAnalyticsEvent", {
                    event    : "update",
                    category : "userAction",
                    label    : "Updating subject " + $scope.data.Subject.ID
                });

                contentService.replace("subject/put/" + $scope.data.Subject.ID, data, callbackSuccess, $scope.callbackFailure);
                break;
            case "new":
                log.v("Creating subject");

                $scope.$emit("emitAnalyticsEvent", {
                    event    : "create",
                    category : "userAction",
                    label    : "Creating new subject"
                });

                contentService.create("subject/create/", data, callbackSuccess, $scope.callbackFailure);
                break;
            default:
                return log.e("Invalid edit mode: ", $scope.editMode);
        }
    };

    $scope.startWizard = function() {
        let wizard   = angular.element("<questionnaire-wizard ng-model=\"wizardSteps\" data-extended=\"" + $scope.extended + "\" data-title=\"Nieuwe lijst uitzetten\" data-subject=\"{{data.Subject}}\"></questionnaire-wizard>");
        let wizardEl = $compile(wizard)($scope);

        $scope.$emit("emitToggleScroll");

        angular.element(document.body).append(wizardEl);
    };

    $scope.stopWizard = function(wizardEl) {
        $scope.$emit("emitToggleScroll");

        angular.element(wizardEl).remove();
    };

    $scope.compareQuestionnaires = function() {
        log.fn("compareQuestionnaires", arguments);

        if($scope.compareArray.length < 2)
            return $scope.quickPop("Error", "U dient minimaal 2 lijsten te selecteren om met elkaar te vergelijken.");

        $scope.go("/questionnaires/report/" + $scope.compareArray.join(","));
    };

    $scope.downloadPDF = function() {
        log.fn("downloadPDF", arguments);

        if($scope.compareArray.length < 1)
            return $scope.quickPop("Error", "U dient een lijst te selecteren om te downloaden.");

        if($scope.compareArray.length > 1)
            return $scope.quickPop("Error", "U kunt maximaal 1 lijst selecteren om te downloaden.");

        $scope.confirmationPopup("Namen in rapportage?", "Wilt u de namen zichtbaar hebben in de rapportage?", function() {
            log.fn("confirmationPopup:confirmed", arguments);

            log.v("Operation confirmed");

            $window.open($window.location.href.split("/app")[0] + "/app/questionnaires/download/" + $scope.compareArray[0]);
        }, function() {
            log.fn("confirmationPopup:confirmed", arguments);

            log.v("Operation cancelled");

            $window.open($window.location.href.split("/app")[0] + "/app/questionnaires/download/" + $scope.compareArray[0] + "/true");
        });
    };

    $scope.toggleCompare = function(stateQuestionnaire, questionnaire) {
        log.fn("toggleCompare", arguments);

        if(stateQuestionnaire[questionnaire.ID]) {
            if($scope.compareCheck === null) {
                $scope.compareCheck = questionnaire;

                $scope.compareArray.push(questionnaire.ID);

                log.d("Added first questionnaire to compare array", $scope.compareArray);
            } else {
                if($scope.compareCheck.Data.Type === questionnaire.Data.Type && $scope.compareCheck.Data.Subject === questionnaire.Data.Subject) {
                    $scope.compareArray.push(questionnaire.ID);

                    log.d("Added questionnaire to compare array", $scope.compareArray);
                } else {
                    stateQuestionnaire[questionnaire.ID] = false;

                    $scope.quickPop("Error", "U kunt enkel lijsten die over dezelfde persoon gaan en van hetzelfde type zijn vergelijken.");
                }
            }
        } else {
            let index = $scope.compareArray.indexOf(questionnaire.ID);

            if(index !== -1) {
                $scope.compareArray.splice(index, 1);

                if($scope.compareArray.length === 0)
                    $scope.compareCheck = null;

                log.d("Removed questionnaire from compare array", $scope.compareArray);
            }
        }
    };

    $scope.remind = function(questionnaire) {
        log.fn("remind", arguments);

        $scope.$emit("emitAnalyticsEvent", {
            event    : "remind",
            category : "userAction",
            label    : "Request remind of questionnaire " + questionnaire.ID
        });

        contentService.create("questionnaire/remind/" + questionnaire.ID, {

        }, function(response) {
            if(response.data.status === "OK") {
                log.v("Response OK received.");

                $scope.quickPop("Melding", response.data.message.Reminders + " " + (parseInt(response.data.message.Reminders) === 1 ? "herinnering" : "herinneringen") + " verstuurd.");
            } else {
                log.w("Erroneous response received.", response.data);

                let title = "Error";
                let content = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined) {
                    window.console.error(response.data.exception);

                    title   = "Internal Exception";
                    content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                }

                $scope.quickPop(title, content);
            }
        }, $scope.callbackFailure);
    };

    $scope.editQuestionnaire = function(questionnaire) {
        log.fn("editQuestionnaire", arguments);

        let questionnaireIdentifier = questionnaire.ID;
        let inviteeExclude          = JSON.stringify(questionnaire.Data.Invited.Raw);

        log.d("Binding data to wizard", {
            questionnaireIdentifier: questionnaireIdentifier,
            inviteeExclude: inviteeExclude
        });

        let wizard   = angular.element("<questionnaire-wizard ng-model=\"editWizardSteps\" data-title=\"Lijst bewerken\" data-questionnaire-identifier=\"" + questionnaireIdentifier + "\" data-invitee-exclude=\"" + inviteeExclude + "\"></questionnaire-wizard>");
        let wizardEl = $compile(wizard)($scope);

        $scope.$emit("emitToggleScroll");

        angular.element(document.body).append(wizardEl);
    };

    $scope.deleteQuestionnaire = function(questionnaire) {
        log.fn("deleteQuestionnaire", arguments);

        $scope.confirmationPopup("Bevestig actie", "Weet u zeker dat u deze lijst wilt verwijderen?", function(popup) {
            log.fn("confirmationPopup:confirmed", arguments);

            log.v("Operation confirmed");

            $scope.$emit("emitAnalyticsEvent", {
                event    : "delete",
                category : "userAction",
                label    : "Request deleteion of questionnaire " + questionnaire.ID
            });

            popup.mutate("Lijst wordt verwijderd", "", []).withLoader();

            contentService.delete("questionnaire/delete/" + questionnaire.ID, function(response) {
                if(response.data.status === "OK") {
                    log.v("Response OK received.");

                    $scope.data.Questionnaires.splice($scope.data.Questionnaires.indexOf(questionnaire), 1);
                    $scope.refreshData();
                    $scope.quickPop("Lijst is verwijderd", "", popup);
                } else {
                    log.w("Erroneous response received.", response.data);

                    let title = "Error";
                    let content = response.data.message || response.data || "Request failed";

                    if(response.data.exception !== undefined) {
                        window.console.error(response.data.exception);

                        title   = "Internal Exception";
                        content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                    }

                    $scope.quickPop(title, content, popup);
                }
            }, function(response) {
                $scope.callbackFailure(response, popup);
            });
        }, function(popup) {
            log.fn("confirmationPopup:cancelled", arguments);

            log.v("Operation cancelled");
        });
    };

    $scope.refreshData = function() {
        contentService.read("subject/profile/" + $scope.id, {

        }, function(response) {
            if(response.data.status === "OK") {
                log.v("Response OK received.");

                $scope.data = response.data.message;

                if(!isNaN(parseFloat($scope.data.Subject.Type)) && isFinite($scope.data.Subject.Type))
                    $scope.$emit("emitSetActiveItem", {item: "" + $scope.data.Subject.Type});
            } else {
                log.w("Erroneous response received.", response.data);

                let title = "Error";
                let content = response.data.message || response.data || "Request failed";

                if(response.data.exception !== undefined) {
                    window.console.error(response.data.exception);

                    title   = "Internal Exception";
                    content = "Reason:<br /><code>" + response.data.exception.message + " at " + response.data.exception.file + ":" + response.data.exception.line + "</code>";
                }

                $scope.quickPop(title, content);
            }
        }, function(response) {
            $scope.data = [];

            $scope.callbackFailure(response);
        });
    };

    if($scope.id !== undefined) {
        $scope.refreshData();

        if($routeParams.edit !== undefined && $routeParams.edit === "edit")
            $scope.editMode = "update";
    } else {
        $scope.type = (!isNaN(parseFloat($routeParams.type)) && isFinite($routeParams.type)) ? parseInt($routeParams.type) : undefined;

        if($scope.type !== undefined) {
            $scope.editMode = "new";

            $scope.$emit("emitAnalyticsEvent", {
                event    : "create",
                category : "userAction",
                label    : "Create new subject"
            });

            $scope.data = {
                Subject : {
                    Type       : $scope.type,
                    Name       : "",
                    MiddleName : "",
                    Surname    : "",
                    Email      : ""
                }
            };
        }
    }

}]);
